import React, {useEffect, useState} from "react";
import {Alert, Form} from "react-bootstrap";
import {authenticationService} from './authentification.service';


function Profile() {
    const [cin, setCin] = useState('');
    const [password, setPassword] = useState('');
    const [passwordv, setPasswordv] = useState(false);
    const [name, setName] = useState("");
    const [namev, setNamev] = useState(false);
    const [lastname, setLastname] = useState("");
    const [lastnamev, setLastnamev] = useState(false);
    const [nationality, setNationality] = useState("saudian");
    const [social_state, setSocial_state] = useState("married");
    const [sexe, setSexe] = useState("male");
    const [phone, setPhone] = useState("");
    const [phonev, setPhonev] = useState(false);
    const [phone2, setPhone2] = useState("");
    const [email, setEmail] = useState("");
    const [messagealert, setMessagealert] = useState("");
    const [messagesuccess, setMessagesuccess] = useState("");
    useEffect(() => {
        authenticationService.get_profile().then((data) => {
            console.log(data)
            setName(data.name)
            setLastname(data.lastname)
            setNationality(data.nationality)
            setPhone(data.phone)
            setPhone2(data.phone2)
            setSexe(data.sexe)
            setSocial_state(data.social_state)
            setCin(data.cin)


        })

    }, [])

    const submitProfile = (e) => {
        e.preventDefault();
        let validation = true


        if (name === '') {
            setNamev(true)
            validation = false
        }
        if (lastname === '') {
            setLastnamev(true)
            validation = false
        }
        if (password.length < 6 && password !== "") {
            setPasswordv(true)
            validation = false
        }


        if (validation) {
            authenticationService.update_profile(password, name, lastname, nationality, social_state, sexe, phone2)
                .then((v) => {
                    console.log(v)
                    setMessagesuccess("تم تحديث الملف الشخصي بنجاح")
                })
                .catch((e) => {
                    console.log(e)
                    setMessagealert("مشكلة في الاتصال")
                })
        } else {
            setMessagealert("حقل خاطئ أو فارغ")
        }
        return false
    }


    return (
        <main role="login">

            <div class="container-fluid banner d-flex justify-content-center align-items-center">
                <div class="container">
                    <h2>ابحث عن خدمة العمالة المناسبة لك</h2>
                </div>
            </div>
            <div class="container">
                <div class="row d-flex justify-content-center mt-4">
                    <div class="col-lg-8 col-md-10">
                        <h3 class="text-center"> الملف الشخصي</h3>
                        <div class="profil custom-form-default mt-4">
                            <Form className="row d-flex justify-content-center " onSubmit={submitProfile}>
                                {(messagealert.length > 0) ?
                                    <Alert style={{width: "100%"}} variant="danger">{messagealert}</Alert> : null}
                                {(messagesuccess.length > 0) ?
                                    <Alert style={{width: "100%"}} variant="success">{messagesuccess}</Alert> : null}
                                <div class="col-lg-10 col-md-12">
                                    <div class="row d-flex justify-content-center">
                                        <div class="col-md-10">


                                            <div
                                                className={(namev) ? "form-group not-valid-form col-md-12 mt-2 mb-4" : "form-group col-md-12 mt-2 mb-4"}>
                                                <label for="static">الاسم الأول:</label>
                                                <input type="text" onChange={(e) => {
                                                    setName(e.target.value)
                                                    setNamev(false)
                                                    setMessagealert('')
                                                    setMessagesuccess("")
                                                }} class="form-control" id="input" value={name}
                                                       placeholder=""/>
                                                <span class="focus-border"></span>
                                            </div>

                                            <div
                                                className={(lastnamev) ? "form-group not-valid-form col-md-12 mt-2 mb-4" : "form-group col-md-12 mt-2 mb-4"}>
                                                <label for="static">العائلة:</label>
                                                <input type="text" onChange={(e) => {
                                                    setLastname(e.target.value)
                                                    setLastnamev(false)
                                                    setMessagealert('')
                                                    setMessagesuccess("")
                                                }} class="form-control" id="input" value={lastname}
                                                       placeholder=""/>
                                                <span class="focus-border"></span>
                                            </div>
                                            <div class="form-group col-md-12 mt-2 mb-4">
                                                <label for="static">الجنسية:</label>
                                                <select value={nationality} onChange={(e) => {
                                                    setNationality(e.target.value)
                                                    setMessagealert('')
                                                    setMessagesuccess("")
                                                }} id="inputState" class="form-control custom-select">
                                                    <option value="saudian">سعودي</option>
                                                    <option value="not_saudian">غير سعودي</option>
                                                </select>
                                                <span class="focus-border"></span>
                                            </div>
                                            <div class="form-group col-md-12 mt-2 mb-4">
                                                <label for="static">رقم بطاقة الهوية:</label>
                                                <input disabled value={cin} type="text" class="form-control" id="input"
                                                       placeholder=""/>
                                                <span class="focus-border"></span>
                                            </div>
                                            <div class="form-group col-md-12 mt-2 mb-4">
                                                <label for="static">الجنس:</label>
                                                <select value={sexe} onChange={(e) => {
                                                    setSexe(e.target.value)
                                                    setMessagealert('')
                                                    setMessagesuccess("")
                                                }} id="inputState" class="form-control custom-select">
                                                    <option value="male">ذكر</option>
                                                    <option value="female">أنثى</option>
                                                </select>
                                                <span class="focus-border"></span>
                                            </div>
                                            <div class="form-group col-md-12 mt-2 mb-4">
                                                <label for="static">الحالية الاجتماعية:</label>
                                                <select value={social_state} id="inputState" onChange={(e) => {
                                                    setSocial_state(e.target.value)
                                                    setMessagealert('')
                                                    setMessagesuccess("")
                                                }} class="form-control custom-select">
                                                    <option value="married">متزوج</option>
                                                    <option value="single">غير متزوج</option>
                                                </select>
                                                <span class="focus-border"></span>
                                            </div>
                                            <div class="form-group col-md-12 mt-2 mb-4">
                                                <label for="static">رقم الجوال:</label>
                                                <input disabled value={phone} type="number" class="form-control"
                                                       id="input"
                                                       placeholder=""/>
                                                <span class="focus-border"></span>
                                            </div>
                                            <div class="form-group col-md-12 mt-2 mb-4">
                                                <label for="static">رقم جوال آخر( اختياري):</label>
                                                <input value={phone2} onChange={(e) => {
                                                    setPhone2(e.target.value)
                                                    setMessagealert('')
                                                    setMessagesuccess("")
                                                }} type="number" class="form-control" id="input"
                                                       placeholder=""/>
                                                <span class="focus-border"></span>
                                            </div>
                                            <div
                                                className={(passwordv) ? "form-group not-valid-form col-md-12 mt-2 mb-4" : "form-group col-md-12 mt-2 mb-4"}>
                                                <label for="static">كلمة المرور:</label>
                                                <input type="password" class="form-control" id="inputpassword"
                                                       value={password} onChange={(e) => {
                                                    setPassword(e.target.value)
                                                    setPasswordv(false)
                                                    setMessagealert('')
                                                    setMessagesuccess("")
                                                }}
                                                       placeholder=""/>
                                                <span class="focus-border"></span>
                                            </div>
                                            <div class="col-md-12 mt-2 mb-4">
                                                <button type="submit"
                                                        class="btn btn-primary btn-login">تحديث
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </Form>
                        </div>
                    </div>
                </div>
            </div>

        </main>

    )
}

export default Profile;









