import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Alert, Dropdown, Modal, Spinner } from "react-bootstrap";
import { authenticationService } from "./authentification.service";
import Axios from "axios";
import Contracts from "./contracts";
import Contract from "./contract";
import _ from "lodash";
import { baseUrl } from "./config";
import Map from "./Map";
import moment from "moment";
import Register from "./register";
import Login from "./login";
import Profile from "./profile";
import Activation from "./activation";
import Myactivation from "./myactivation";
import Forgotpassword from "./fogotpassword"
import Resetpassword from "./resetpassword"
import About from "./about"

function getSearchParams(k) {
    var p = {};
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (s, k, v) {
        p[k] = v;
    });
    return k ? p[k] : p;
}

function setQueryStringWithoutPageReload(qsValue) {
    const newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        qsValue;

    window.history.pushState({ path: newurl }, "", newurl);
}

function App() {
    let today = moment().format("YYYY-M-D");
    const { t, i18n } = useTranslation();

    let tomorrow = moment().add(1, "days").format("YYYY-M-D");

    const [activeKey, setactiveKey] = useState(999);
    const [maxKey, setmaxKey] = useState(999);
    const [window, setwindow] = useState("main");
    const [currentUser, setcurrentUser] = useState(null);
    const [requirement, setRequirement] = useState(false);
    const [vrequirement, setvRequirement] = useState(false);
    const [vpayment_type, setvpayment_type] = useState(false);

    const [date_receive, setDate_receive] = useState(tomorrow);
    const [payment_type, setPayment_type] = useState("bank_transfert");
    const [checknationality, setChecknationality] = useState("");
    const [checkcategory, setcheckcategory] = useState(0);
    const [transfert_type, setTransfert_type] = useState("");
    const [address, setAdress] = useState("");
    const [duration, setDuration] = useState("");
    const [employees, setEmployees] = useState([]);
    const [packs, setPacks] = useState([]);
    const [employee, setEmployee] = useState({});
    const [successorder, setsuccessorder] = useState(false);
    const [cin, setcin] = useState("");
    const [pass_code, setpass_code] = useState("");

    const [base64_img, setbase64_img] = useState({ file: "", name: "ملف ..." });

    const [pos, setPos] = useState({ lat: 24.774265, lng: 46.738586 });
    const [zoom, setzoom] = useState(10);
    const [modal_map, setModal_map] = useState(false);
    const [alerte, setalert] = useState({ status: false, message: "" });
    const [loading, setloading] = useState(false);
    const [modal_detail, setModal_detail] = useState({
        status: false,
        employee: [],
    });

    const [modal_rules, setModal_rules] = useState(false);
    const [categories, setCategories] = useState([]);
    const [housekeeper_id, sethousekeeper_id] = useState(-1);
    const [driver_id, setdriver_id] = useState(-1);
    const [worker_id, setworker_id] = useState(-1);
    // const [errorpayement, seterrorpayement] = useState("");
    const [setting, setSetting] = useState({});
    useEffect(() => {
        let error = getSearchParams("error");
        let success = getSearchParams("success");
        let id = getSearchParams("id");
        let language = localStorage.getItem('language');
        if (language === "en") i18n.changeLanguage('en')
        if (language === "ar") i18n.changeLanguage('ar')
        if ((error || success) && id) setwindow("contract");
        authenticationService.currentUser.subscribe((x) => setcurrentUser(x));
        Axios.get(`${baseUrl}/api/employees`).then((v) => {
            setEmployees(v.data.data);
        });
        Axios.get(`${baseUrl}/api/packs`).then((v) => {
            setPacks(v.data.data);
        });
        Axios.get(`${baseUrl}/api/settings/1`).then((v) => {
            setSetting(v.data.data);
        });
        Axios.get(`${baseUrl}/api/categories`).then((v) => {
            let categoris = v.data.data;
            setCategories(categoris);
            categoris.forEach((V) => {
                if (V.title === "عاملة منزلية") sethousekeeper_id(V.id);
                if (V.title === "سائق خاص") setdriver_id(V.id);
                if (V.title === "عامل منزلي") setworker_id(V.id);

            });
            // setCategories(v.data.data);
        });

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                var pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };

                setPos(pos);
                setzoom(15);
            });
        }
    }, []);

    const make_order = () => {
        console.log((!requirement)
            , (payment_type === "")
            , (payment_type === "bank_transfert" && base64_img.file === "")
            , (!setting.branch && transfert_type === "branche")
            , (!setting.transfert && transfert_type === "transfert")
            , (!setting.online_payment && payment_type === "payment")
            , (!setting.banktransfert_payment && payment_type === "bank_transfert"))

        if (
            (!requirement)
            || (payment_type === "")
            || (payment_type === "bank_transfert" && base64_img.file === "")
            || (!setting.branch && transfert_type === "branche")
            || (!setting.transfert && transfert_type === "transfert")
            || (!setting.online_payment && payment_type === "payment")
            || (!setting.banktransfert_payment && payment_type === "bank_transfert")
        ) {

            if (!requirement) setvRequirement(true)
            if (payment_type === "") setvpayment_type(true)
            setalert({ status: true, message: t("wrongfield") })
        } else {
            make_order1();

        }
    }

    const make_order1 = () => {
        if (currentUser === null) {
            setwindow("login");
            setsuccessorder(false);
        } else {
            if (date_receive === today || date_receive === tomorrow) {
                setloading(true);
                let employee_id = employee.id;
                let mypack = packs.filter((v) => {
                    return (
                        v.nationality.code === checknationality &&
                        v.category.id === checkcategory
                    );
                });
                let total = mypack[0] ? mypack[0][duration] : 0;
                let tax = (total * mypack[0]["tax"]) / 100;
                let price = total + tax;
                // if (duration === "pack_3" && checknationality === "ID") price = 9387;
                let pack_id = mypack[0].id;
                let pack_duration = duration.split("_")[1];
                let map_position = address ? JSON.stringify(pos) : "";
                let manual_payment = payment_type === "cash_delivery";
                let adress = address;
                let payement_type = payment_type;
                let start_at = date_receive;
                let image_payement = base64_img.file;
                authenticationService
                    .order(
                        employee_id,
                        pack_id,
                        pack_duration,
                        transfert_type,
                        price,
                        map_position,
                        manual_payment,
                        adress,
                        payement_type,
                        start_at,
                        image_payement
                    )
                    .then((v) => {
                        if (v.url) {
                            document.location = v.url;
                        } else {

                            setactiveKey(1000);
                            setmaxKey(1000);
                            setRequirement(false);
                            setPayment_type("");
                            setChecknationality("");
                            setTransfert_type("");
                            setAdress("");
                            setDuration("");
                            setEmployee([]);
                            setsuccessorder(true);
                            setloading(false);
                            setQueryStringWithoutPageReload(`?id=${v.id}`);
                            setwindow("contract");
                        }
                    })
                    .catch((e) => {
                        setactiveKey(1000);
                        setmaxKey(1000);
                        setloading(false);
                        setRequirement(false);
                        setPayment_type("");
                        setChecknationality("");
                        setTransfert_type("");
                        setAdress("");
                        setDuration("");
                        setEmployee([]);
                        if (e.code === 307) {
                            setalert({ status: true, message: e.error });
                        } else {
                            setalert({ status: true, message: "مشكلة إتصال" });
                        }
                    });
            } else {
                setalert({
                    status: true,
                    message: "يوم بداية العقد محدد إما اليوم أو الغد",
                });
            }
        }
    };
    const callback = (pos, adress, zoom) => {
        setAdress(adress);
        setPos(pos);
        setzoom(zoom);
    };
    const callbackwindow = (v) => {
        setwindow(v);
    };
    const callbackcin = (v) => {
        setcin(v);
    };
    const callbackpass = (v) => {
        setpass_code(v);
    };

    const listemployees = () => {
        let res = [];

        let myemployees = employees.filter((v) => {
            return (
                v.nationality_value === checknationality &&
                v.category_id === checkcategory
            );
        });

        for (let i = 0; i < myemployees.length; i++) {
            let employ = myemployees[i];
            res.push(
                <div className="col-lg-6 col-md-12">
                    <div
                        className={
                            employee.id === employ.id ? "card-worker active" : "card-worker"
                        }
                    >
                        <div className="top-card-worker">
                            <div className="photo-worker">
                                <img
                                    src={
                                        myemployees[i].image
                                            ? `${baseUrl}/${myemployees[i].image}`
                                            : "./img/no-image.png"
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="info-worker">
                                <span className="name-worker">{myemployees[i].name}</span>
                                <span className="country">
                                    {" "}
                                    {i18n.t(modal_detail.employee.nationality_value)}
                                </span>
                                <span className="sex-age">
                                    <b>
                                        {i18n.t("age")}: {moment().diff(myemployees[i].birth_date, "years")}{" "}
                                        {i18n.t("year")}
                                    </b>{" "}
                                </span>
                                <span className="sex-age">
                                    {i18n.t("sexe")}: {myemployees[i].sexe === "Female" ? i18n.t("female") : i18n.t("male")}{" "}
                                </span>
                                <span className="sex-age">
                                    {i18n.t("religion")}:{" "}
                                    {myemployees[i].religion === "muslim"
                                        ? i18n.t("muslim")
                                        : i18n.t("not_muslim")}
                                </span>
                                <span className="sex-age">
                                    <b>{i18n.t("experience")}: {myemployees[i].experience} {i18n.t("year")}</b>
                                </span>
                            </div>
                        </div>
                        <div className="bottom-worker">
                            <a
                                href="#"
                                onClick={() => {
                                    setModal_detail({ status: true, employee: employ });
                                }}
                                className="more-info"
                            >
                                {i18n.t("cv")}
                            </a>
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault()
                                    setEmployee(employ);
                                    let newkey = activeKey + 1;
                                    setactiveKey(newkey);
                                    if (newkey > maxKey) {
                                        setmaxKey(newkey);
                                    }
                                }}
                                className="btn reserv-worker"
                            >
                                {i18n.t("choose")}
                            </a>
                        </div>
                    </div>
                </div>
            );
        }
        if (res.length === 0)
            return [
                <Alert
                    style={{ width: "100%", textAlign: "center", margin: 5 }}
                    variant="info"
                >
                    {t("nocvavaileble")}
                    &nbsp; &nbsp; &nbsp;
                    <a className="btn btn-sm btn-success"
                        href="https://jr.sa/individuals/#indv-form">{t("clickhere")}</a>
                </Alert>,
            ];
        else {
            return res;
        }
    };

    const selectedemployee = () => {
        let res = [];

        let myemployees = employees.filter((v) => {
            return (
                v.nationality_value === checknationality &&
                v.category_id === checkcategory
            );
        });

        for (let i = 0; i < myemployees.length; i++) {
            let employ = myemployees[i];
            if (employee.id === employ.id)
                res.push(
                    <div className="col-lg-6 col-md-12">
                        <div className="card-worker">
                            <div className="top-card-worker">
                                <div className="photo-worker">
                                    <img
                                        src={
                                            myemployees[i].image
                                                ? `${baseUrl}/${myemployees[i].image}`
                                                : "./img/no-image.png"
                                        }
                                        alt=""
                                    />
                                </div>
                                <div className="info-worker">
                                    <span className="name-worker">{myemployees[i].name}</span>
                                    <span className="country">
                                        {" "}
                                        {i18n.t(modal_detail.employee.nationality_value)}
                                    </span>
                                    <span className="sex-age">
                                        <b>
                                            {i18n.t("age")}: {moment().diff(myemployees[i].birth_date, "years")}{" "}
                                            {i18n.t("year")}
                                        </b>{" "}
                                    </span>
                                    <span className="sex-age">
                                        {i18n.t("sexe")}: {myemployees[i].sexe === "Female" ? i18n.t("female") : i18n.t("male")}{" "}
                                    </span>
                                    <span className="sex-age">
                                        {i18n.t("religion")}:{" "}
                                        {myemployees[i].religion === "muslim"
                                            ? i18n.t("muslim")
                                            : i18n.t("not_muslim")}
                                    </span>
                                    <span className="sex-age">
                                        <b>{i18n.t("experience")}: {myemployees[i].experience} {i18n.t("year")}</b>
                                    </span>
                                </div>
                            </div>
                            <div className="bottom-worker"></div>
                        </div>
                    </div>
                );
        }
        return res;
    };

    const customTable = () => {
        let res = [];
        let mypack = packs.filter((v) => {
            return (
                v.nationality.code === checknationality &&
                v.category.id === checkcategory
            );
        });
        let price = mypack[0] ? mypack[0][duration] : 0;
        let tax = (price * mypack[0]["tax"]) / 100;
        let total = price + tax;

        if (duration === "pack_33" && checknationality === "ID") {
            res.push(
                <div className=" custom-table">
                    <table className="table">
                        <tbody>
                            <tr>
                                <th scope="row">
                                    <span className="title-price">{i18n.t("offer")}</span>
                                </th>
                                <td>
                                    <span style={{ color: "#00e600" }} className="desc-price">
                                        عرض الخير
                                    </span>
                                </td>
                            </tr>

                            <tr>
                                <th scope="row">
                                    <span className="title-price">{i18n.t("serviceprice")}</span>
                                </th>
                                <td>
                                    <span
                                        style={{ textDecoration: "line-through" }}
                                        className="desc-price"
                                    >
                                        {price}{" "}
                                    </span>
                                    <span style={{ color: "#00e600" }} className="desc-price">
                                        8940 {i18n.t("sr")}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <span className="title-price">{i18n.t("tax")}</span>
                                </th>
                                <td>
                                    <span
                                        style={{ textDecoration: "line-through" }}
                                        className="desc-price"
                                    >
                                        {tax}{" "}
                                    </span>
                                    <span style={{ color: "#00e600" }} className="desc-price">
                                        447 {i18n.t("sr")}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <span className="title-price">{i18n.t("total_v")}</span>
                                </th>
                                <td>
                                    <span
                                        style={{ textDecoration: "line-through" }}
                                        className="desc-price"
                                    >
                                        {total}{" "}
                                    </span>
                                    <span style={{ color: "#00e600" }} className="desc-price">
                                        9387 {i18n.t("sr")}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        } else {
            res.push(
                <div className=" custom-table">
                    <table className="table">
                        <tbody>
                            <tr>
                                <th scope="row">
                                    <span className="title-price">{i18n.t("serviceprice")}</span>
                                </th>
                                <td>
                                    <span className="desc-price">{price}</span>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <span className="title-price">{i18n.t("tax")}</span>
                                </th>
                                <td>
                                    <span className="desc-price">{tax}</span>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <span className="title-price">{i18n.t("total_v")}</span>
                                </th>
                                <td>
                                    <span className="desc-price">{total} {i18n.t("sr")}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }

        return res;
    };
    const _uploadFile = (e) => {
        //...
        const _inp = e.target;
        const _files = _inp.files;

        Array.from(_files).forEach((file) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setbase64_img({ file: reader.result, name: file.name });
            };
        });
        _inp.value = "";
    };

    const durationList = () => {
        let res = [];
        let list = [
            "pack_1",
            "pack_2",
            "pack_3",
            "pack_4",
            "pack_5",
            "pack_6",
            "pack_12",
        ];
        let mypack = packs.find((v) => {
            return (
                v.nationality.code === checknationality &&
                v.category.id === checkcategory
            );
        });

        if (mypack) {
            for (let i = 0; i < list.length; i++) {
                let pa = list[i];
                if (mypack[pa] && mypack[pa] > 0) {
                    res.push(<option value={pa}>{pa.split("_")[1]}</option>);
                }
            }
        }
        return res;

    };


    const nationalityList = () => {
        let res = []
        let mypacks = packs.filter((v) => {
            return (
                v.category.id === checkcategory
            );
        });

        for (let i = 0; i < mypacks.length; i++) {
            if (!mypacks[i].isnull)
                res.push(
                    <div className="col-md-4 col-sm-6 col-6">
                        <div className="custom-control custom-radio custom-control-inline mb-4">
                            <input
                                type="radio"
                                id={mypacks[i].nationality.code}
                                name="nationality"
                                className="custom-control-input"
                                checked={checknationality === mypacks[i].nationality.code}
                            />
                            <label
                                className="custom-control-label"
                                htmlFor={mypacks[i].nationality.code}
                            >
                                <img
                                    src={`./img/${mypacks[i].nationality.code}.png`}
                                    alt=""
                                    style={{ margin: 5 }}
                                />
                                {i18n.t(mypacks[i].nationality.code)}
                            </label>
                        </div>
                    </div>
                )
        }


        return res
    }


    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (<a
        href="#"
        ref={ref}
        className="btn language"
        data-label={t("lang")}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >


        <i className="icon-world"></i>
        <span style={{ margin: 5 }}> {children} &#x25bc;</span>
    </a>
    ));
    const CustomToggle2 = React.forwardRef(({ children, onClick }, ref) => (<a
        href="#"
        ref={ref}
        className="btn registration-header"
        data-label={t("myaccount")}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >


        {t("myaccount")}
        <span style={{ margin: 5 }}> {children} &#x25bc; </span>
    </a>
    ));
    let packs_cat = categories.reduce((t, c) => {
        return { ...t, [c.id]: true }
    }, {})
    packs_cat = packs.reduce((t, c) => {

        return { ...t, [c.category_id]: t[c.category_id] && c.isnull }

    }, packs_cat)
    return (
        <div style={{ textAlign: (i18n.language === "ar") ? "right" : "left" }}
            className={i18n.language === "ar" ? "rtl" : ""}>
            <header className="container-fluid header fixed-top">
                <div className="container">
                    <nav className="navbar navbar-expand-lg pt-2 pb-2 row menuH">
                        <a href="//jr.sa" className="navbar-brand">
                            <span className="logo">
                                {" "}
                                <img src="//jr.sa/images/logo.png" alt="Al Jeraisy Logo" />
                            </span>
                        </a>
                        <button
                            className="navbar-toggler hamburger"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarCollapse"
                            aria-controls="navbarCollapse"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <i className="ri-menu-fill"></i>
                        </button>
                        <div
                            className="navbar-collapse collapse justify-content-between mr-3"
                            id="navbarCollapse"
                        >
                            <ul className="navbar-nav ">
                                <li className="nav-item">
                                    <a href="/">
                                        <i className="icon-home"></i>
                                        {i18n.t("home")}{" "}
                                        <span className="sr-only">(current)</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="//jr.sa/about">
                                        <i className="icon-info"></i>
                                        {i18n.t("aboutus")}{" "}
                                    </a>
                                </li>

                                {currentUser !== null ? (
                                    <li className="nav-item d-md-flex d-lg-none">
                                        <a
                                            className="nav-link"
                                            onClick={() => {
                                                setwindow("contracts");
                                                setsuccessorder(false);
                                            }}
                                            href="#"
                                        >
                                            <i className="icon-contract"></i>
                                            {i18n.t("contracts")} <span className="sr-only">(current)</span>
                                        </a>
                                    </li>
                                ) : null}

                                {currentUser !== null ? (
                                    <li className="nav-item d-md-flex d-lg-none">
                                        <a
                                            className="nav-link"
                                            onClick={() => {
                                                setwindow("profile");
                                                setsuccessorder(false);
                                            }}
                                            href="#"
                                        >
                                            <i className="icon-user"></i>
                                            {i18n.t("profile")} <span className="sr-only">(current)</span>
                                        </a>
                                    </li>
                                ) : null}

                            </ul>
                            <div className="d-flex button-action ">


                                <Dropdown>
                                    <Dropdown.Toggle
                                        as={CustomToggle}
                                        id="dropdown-custom-components"
                                    >{i18n.t("lang")}</Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() => {
                                                i18n.changeLanguage("en");
                                                localStorage.setItem("language", "en")
                                            }}
                                            eventKey="1"
                                            active={i18n.language === "en"}
                                        >
                                            En
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                i18n.changeLanguage("ar");
                                                localStorage.setItem("language", "ar")

                                            }}
                                            eventKey="2"
                                            active={i18n.language === "ar"}
                                        >
                                            Ar
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>


                                {currentUser !== null ? (<Dropdown className="d-none d-lg-flex">
                                    <Dropdown.Toggle
                                        as={CustomToggle2}
                                        id="dropdown-custom-components"
                                    ></Dropdown.Toggle>

                                    <Dropdown.Menu style={{ textAlign: (i18n.language === "ar") ? "right" : "left" }}>


                                        <>     <Dropdown.Item

                                            onClick={() => {
                                                setwindow("contracts");
                                                setsuccessorder(false);
                                            }}
                                            className="nav-link"
                                        >
                                            {i18n.t("contracts")}
                                        </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    setwindow("profile");
                                                    setsuccessorder(false);
                                                }}
                                                className="nav-link"
                                            >
                                                {i18n.t("profile")}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    authenticationService.logout();
                                                    setwindow("main");
                                                }}
                                                className="nav-link"
                                            >
                                                {i18n.t("logout")}
                                            </Dropdown.Item>
                                        </>

                                    </Dropdown.Menu>
                                </Dropdown>
                                ) : null}


                                {currentUser === null ? (
                                    <>
                                        <a
                                            href="#"
                                            onClick={() => {
                                                setwindow("register");
                                            }}
                                            className="btn registration-header "
                                        >
                                            <i className="icon-user"></i>
                                            <span> {i18n.t("register")} </span>
                                        </a>
                                        <a
                                            href="#"
                                            onClick={() => {
                                                setwindow("login");
                                            }}
                                            className="btn login-header "
                                        >
                                            <i className="icon-add"></i>
                                            <span> {i18n.t("login")} </span>
                                        </a>
                                    </>
                                ) : null}
                                {currentUser !== null ? (
                                    <>
                                        <a
                                            href="#"
                                            onClick={() => {
                                                authenticationService.logout();
                                                setwindow("main");
                                            }}
                                            className="btn login-header d-md-flex d-lg-none"
                                        >
                                            <i className="icon-exit"></i>
                                            <span>{i18n.t("logout")} </span>
                                        </a>
                                    </>
                                ) : null}


                                <div className="contact-mobile">
                                    <h3>تواصل معنا</h3>
                                    <ul>
                                        <li><i className="icon-marker"></i> المملكة العربية السعودية</li>
                                        <li><i className="icon-phone"></i>920000239</li>
                                        <li><a href="mailto:info@jr.sa">info@jr.sa<i className="icon-send"></i></a></li>
                                    </ul>
                                    <div className="social-network">
                                        <a href="https://www.facebook.com/%D8%B4%D8%B1%D9%83%D8%A9-%D8%B9%D8%A8%D8%AF%D8%A7%D9%84%D9%85%D8%AC%D9%8A%D8%AF-%D8%B9%D8%A8%D8%AF%D8%A7%D9%84%D8%B1%D8%AD%D9%85%D9%86-%D8%A7%D9%84%D8%AC%D8%B1%D9%8A%D8%B3%D9%8A-%D9%84%D9%84%D9%85%D9%88%D8%A7%D8%B1%D8%AF-%D8%A7%D9%84%D8%A8%D8%B4%D8%B1%D9%8A%D8%A9-101741904813841"
                                            target="_blank"><img src="./img/icon-facebook.png" alt="" /></a>
                                        <a href="https://www.instagram.com/jeraisyhr/?hl=en" target="_blank"><img
                                            src="./img/icon-instagram.png" alt="" /></a>
                                        <a href="https://www.linkedin.com/company/aljeraisy-recruitment/"
                                            target="_blank"><img src="./img/icon-linkedIn.png" alt="" /></a>
                                        <a href="https://twitter.com/JeraisyHR" target="_blank"><img
                                            src="./img/icon-twitter.png" alt="" /></a>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </nav>
                </div>
            </header>

            {window === "main" ? (
                <main role="main">
                    {/* <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <a href="//onelink.to/ytryde" target="_blank">
                                    <img src="/images/najdah-app-link.jpg" className="img-fluid"/>
                                </a>
                            </div>
                        </div>
                    </div>*/}
                    <div className="container steps mt-4">

                        {alerte.status ? (
                            <Alert variant="danger">{alerte.message}</Alert>
                        ) : null}

                        <Accordion activeKey={activeKey}>


                            <div className="portlet">
                                <div className="headerPortlet">
                                    <div className="num-steps">
                                        <h3> {i18n.t("profession")} </h3>
                                    </div>
                                    <div className="arrow-left"></div>
                                </div>
                                <div className="bodyPortlet">
                                    <div className="row nationality mt-4 mb-3">
                                        <div className=" col-md-12">
                                            <div
                                                onChange={(e) => {
                                                    setcheckcategory(parseInt(e.target.id));
                                                    let newkey = 1000;
                                                    setactiveKey(newkey);
                                                    setsuccessorder(false);
                                                    setChecknationality("");
                                                    setalert({ status: false, message: "" });
                                                    setmaxKey(newkey);
                                                    setRequirement(false);
                                                    setPayment_type("");
                                                    setTransfert_type("");
                                                    setAdress("");
                                                    setDuration("");
                                                    setEmployee([]);
                                                }}
                                                className="row"
                                            >
                                                <div className="col-md-4 col-sm-6 col-6">
                                                    <div
                                                        className="custom-control custom-radio custom-control-inline mb-4">
                                                        <input
                                                            disabled={housekeeper_id === -1 || packs_cat[housekeeper_id]}
                                                            checked={checkcategory === housekeeper_id}
                                                            type="radio"
                                                            id={housekeeper_id}
                                                            name="customRadioInline1"
                                                            className="custom-control-input"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            for={housekeeper_id}
                                                        >
                                                            <img height={30} src="./img/maid.png" alt="" />
                                                            {i18n.t("housekeeper")}
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-6 col-6">
                                                    <div
                                                        className="custom-control custom-radio custom-control-inline mb-4">
                                                        <input
                                                            disabled={driver_id === -1 || packs_cat[driver_id]}
                                                            checked={checkcategory === driver_id}
                                                            type="radio"
                                                            id={driver_id}
                                                            name="customRadioInline1"
                                                            className="custom-control-input"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            for={driver_id}
                                                        >
                                                            <img height={30} src="./img/driver.png" alt="" />
                                                            {i18n.t("driver")}
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-6 col-6">
                                                    <div
                                                        className="custom-control custom-radio custom-control-inline mb-4">
                                                        <input
                                                            disabled={worker_id === -1 || packs_cat[worker_id]}
                                                            checked={checkcategory === worker_id}
                                                            type="radio"
                                                            id={worker_id}
                                                            name="customRadioInline1"
                                                            className="custom-control-input"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            for={worker_id}
                                                        >
                                                            <img height={30} src="./img/worker.png" alt="" />
                                                            {i18n.t("houseworker")}
                                                        </label>
                                                    </div>
                                                </div>
                                                {setting.job_info ? (
                                                    <ul className="col-12 ">
                                                        <li style={{ margin: 10 }}>
                                                            {setting.job_info}
                                                        </li>
                                                    </ul>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {maxKey >= 1000 ? (
                                <div className="portlet" id="t1000">
                                    <Accordion.Toggle
                                        onClick={(e) => {
                                            setactiveKey(1000);
                                        }}
                                        className="headerPortlet"
                                        style={{ backgroundColor: "#fff" }}
                                        eventKey={1000}
                                    >
                                        <div className="num-steps">
                                            <span className="number">1</span>
                                            <h3 style={{ margin: 5 }}>{i18n.t("nationality_t")}</h3>
                                        </div>
                                        <div className="arrow-left">
                                            <a href="#">
                                                {" "}
                                                <i className="ri-arrow-up-s-line"></i>
                                            </a>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={1000}>
                                        <div className="bodyPortlet">
                                            <form className="row nationality mt-4 mb-3">
                                                <div className="col-md-12">
                                                    <div
                                                        onChange={(e) => {
                                                            setChecknationality(e.target.id);
                                                            let newkey = activeKey + 1;
                                                            setactiveKey(newkey);
                                                            setsuccessorder(false);
                                                            setalert({ status: false, message: "" });
                                                            if (newkey > maxKey) {
                                                                setmaxKey(newkey);
                                                            }
                                                        }}
                                                        className="row"
                                                    >
                                                        {nationalityList()}
                                                        {setting.nationality_info ? (
                                                            <ul className="col-12 ">
                                                                <li style={{ margin: 10 }}>
                                                                    {setting.nationality_info}
                                                                </li>
                                                            </ul>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            ) : (
                                <div className="portlet closed disabled">
                                    <div className="headerPortlet">
                                        <div className="num-steps">
                                            <span className="number">1</span>
                                            <h3 style={{ margin: 5 }}>{i18n.t("nationality_t")}</h3>
                                        </div>
                                        <div className="arrow-left">
                                            <a href="#" disabled>
                                                <i className="ri-arrow-down-s-line"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {maxKey >= 1001 ? (
                                <>
                                    {" "}
                                    <div className="portlet" id="t1001">
                                        <Accordion.Toggle
                                            onClick={(e) => {
                                                setactiveKey(1001);
                                            }}
                                            className="headerPortlet"
                                            style={{ backgroundColor: "#fff" }}
                                            eventKey={1001}
                                        >
                                            <div className="num-steps">
                                                <span className="number">2</span>
                                                <h3 style={{ margin: 5 }}>{i18n.t("receiving_method")}</h3>
                                            </div>
                                            <div className="arrow-left">
                                                <a href="#">
                                                    {" "}
                                                    <i className="ri-arrow-up-s-line"></i>
                                                </a>
                                            </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={1001}>
                                            <div className="bodyPortlet">
                                                <div
                                                    onChange={(e) => {
                                                        setTransfert_type(e.target.id);
                                                        if (e.target.id === "branche") {
                                                            let newkey = activeKey + 1;
                                                            setactiveKey(newkey);
                                                            if (newkey > maxKey) {
                                                                setmaxKey(newkey);
                                                            }
                                                        }
                                                    }}
                                                    className="row custom-form-default mt-4 mb-3"
                                                >
                                                    <div className="col-md-12">
                                                        <div className="custom-control custom-radio mb-4">
                                                            <input
                                                                checked={transfert_type === "branche"}
                                                                type="radio"
                                                                id="branche"
                                                                name="transfert_type"
                                                                className="custom-control-input"
                                                                disabled={!setting.branch}
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="branche"
                                                            >
                                                                {i18n.t("branche")}
                                                                {/*<a*/}
                                                                {/*    style={{marginRight: 5}}*/}
                                                                {/*    href={officeLocationLink}*/}
                                                                {/*    target="_blank"*/}
                                                                {/*>*/}
                                                                {/*    ({i18n.t("show_map")})*/}
                                                                {/*</a>*/}
                                                            </label>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-12">
                                                        <div className="custom-control custom-radio mb-4">
                                                            <input
                                                                checked={transfert_type === "transfert"}
                                                                type="radio"
                                                                id="transfert"
                                                                name="transfert_type"
                                                                className="custom-control-input"
                                                                disabled={!setting.transfert}
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="transfert"
                                                            >
                                                                {i18n.t("transfert")}
                                                            </label>
                                                        </div>
                                                    </div>

                                                    {setting.transfert_info ? (
                                                        <ul className="col-12 ">
                                                            <li style={{ margin: 10 }}>
                                                                {setting.transfert_info}
                                                            </li>
                                                        </ul>
                                                    ) : null}
                                                </div>

                                                <div className="row custom-form-default mt-4 mb-3">
                                                    {transfert_type === "transfert" ? (
                                                        <>
                                                            <div className="form-group col-lg-6 col-md-12 mt-3">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="input"
                                                                    onChange={(e) => {
                                                                        setAdress(e.target.value);
                                                                    }}
                                                                    value={address}
                                                                    placeholder={i18n.t("address")}
                                                                />
                                                                <span className="focus-border"></span>
                                                                <a
                                                                    href="#"
                                                                    type="button"
                                                                    className="position"
                                                                    onClick={() => {
                                                                        setModal_map(true);
                                                                    }}
                                                                >
                                                                    <i className="ri-map-pin-2-line"></i>
                                                                </a>
                                                            </div>
                                                            <Modal
                                                                show={modal_map}
                                                                onHide={() => setModal_map(false)}
                                                                dialogClassName="modal-default"
                                                                aria-labelledby="exampleModalCenterTitle"
                                                            >
                                                                <div
                                                                    className="modal-dialog "
                                                                    style={{ margin: 0 }}
                                                                >
                                                                    <div className="modal-content">
                                                                        <Modal.Header className="modal-header">
                                                                            <div className="content-info-header-modal">
                                                                                <button
                                                                                    type="button"
                                                                                    className="close"
                                                                                    onClick={() => {
                                                                                        setModal_map(false);
                                                                                    }}
                                                                                >
                                                                                    <span aria-hidden="true">
                                                                                        <i className="ri-close-line"></i>
                                                                                    </span>
                                                                                </button>
                                                                                <h5
                                                                                    className="modal-title"
                                                                                    id="exampleModalCenterTitle"
                                                                                >
                                                                                    {i18n.t("address")}
                                                                                </h5>
                                                                            </div>

                                                                            <div className="content-save-info">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary save-location"
                                                                                    onClick={() => {
                                                                                        if (address !== "") {
                                                                                            let newkey = activeKey + 1;
                                                                                            setactiveKey(newkey);
                                                                                            setModal_map(false);
                                                                                            if (newkey > maxKey) {
                                                                                                setmaxKey(newkey);
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {i18n.t("save")}
                                                                                </button>
                                                                            </div>
                                                                        </Modal.Header>
                                                                        <Modal.Body
                                                                            className="custom-form-default modal-body">
                                                                            <form className="row">
                                                                                <div
                                                                                    className="form-group col-md-12 mt-4 mb-4">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        id="input"
                                                                                        value={address}
                                                                                        onChange={(e) => {
                                                                                            setAdress(e.target.value);
                                                                                        }}
                                                                                        placeholder={i18n.t("address")}
                                                                                    />
                                                                                    <span
                                                                                        className="focus-border"></span>
                                                                                </div>

                                                                                <div className="col-md-12  mb-2">
                                                                                    <div className="block-map ">
                                                                                        <div
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                height: "70vh",
                                                                                            }}
                                                                                        >
                                                                                            <Map
                                                                                                center={pos}
                                                                                                height="300px"
                                                                                                zoom={zoom}
                                                                                                callback={callback}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </Modal.Body>
                                                                    </div>
                                                                </div>
                                                            </Modal>
                                                        </>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>
                                </>
                            ) : (
                                <div className="portlet closed disabled">
                                    <div className="headerPortlet">
                                        <div className="num-steps">
                                            <span className="number">2</span>
                                            <h3 style={{ margin: 5 }}>{i18n.t("receiving_method")}</h3>
                                        </div>
                                        <div className="arrow-left">
                                            <a href="#" disabled>
                                                <i className="ri-arrow-down-s-line"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {maxKey >= 1002 ? (
                                <div className="portlet" id="t1002">
                                    <Accordion.Toggle
                                        className="headerPortlet"
                                        onClick={(e) => {
                                            setactiveKey(1002);
                                        }}
                                        style={{ backgroundColor: "#fff" }}
                                        eventKey={1002}
                                    >
                                        <div className="num-steps">
                                            <span className="number">3</span>
                                            <h3 style={{ margin: 5 }}>{i18n.t("contract_period")}</h3>
                                        </div>
                                        <div className="arrow-left">
                                            <a href="#">
                                                {" "}
                                                <i className="ri-arrow-up-s-line"></i>
                                            </a>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={1002}>
                                        <div className="bodyPortlet">
                                            <div className="row custom-form-default mt-3 mb-5">
                                                {setting.duration_info ? (
                                                    <ul className="col-12 ">
                                                        <li style={{ margin: 10 }}>
                                                            {setting.duration_info}
                                                        </li>
                                                    </ul>
                                                ) : null}
                                                <div className="col-lg-6 col-md-12">
                                                    <select
                                                        id="inputState"
                                                        value={duration}
                                                        onChange={(e) => {
                                                            setDuration(e.target.value);
                                                            let newkey = activeKey + 1;
                                                            setactiveKey(newkey);
                                                            if (newkey > maxKey) {
                                                                setmaxKey(newkey);
                                                            }

                                                        }}
                                                        className="form-control custom-select"
                                                    >
                                                        <option value=""> {i18n.t("contract_period_months")}</option>
                                                        {/* <option value="pack_1" >1</option> */}
                                                        {durationList()}
                                                    </select>
                                                    <span className="focus-border"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            ) : (
                                <div className="portlet closed disabled">
                                    <div className="headerPortlet">
                                        <div className="num-steps">
                                            <span className="number">3</span>
                                            <h3 style={{ margin: 5 }}>{i18n.t("contract_period")}</h3>
                                        </div>
                                        <div className="arrow-left">
                                            <a href="#" disabled>
                                                <i className="ri-arrow-down-s-line"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {maxKey >= 1003 ? (
                                <div className="portlet" id="t1003">
                                    <Accordion.Toggle
                                        onClick={(e) => {
                                            setactiveKey(1003);

                                        }}
                                        className="headerPortlet"
                                        style={{ backgroundColor: "#fff" }}
                                        eventKey={1003}
                                    >
                                        <div className="num-steps">
                                            <span className="number">4</span>
                                            <h3 style={{ margin: 5 }}> {i18n.t("choosingworker")} </h3>
                                        </div>

                                        <div className="arrow-left">
                                            <a href="#">
                                                {" "}
                                                <i className="ri-arrow-up-s-line"></i>
                                            </a>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={1003}>
                                        <div className="bodyPortlet">
                                            <h4> {i18n.t("listemployment")} </h4>
                                            <div className="row">
                                                {setting.cv_info ? (
                                                    <ul className="col-12 ">
                                                        <li style={{ margin: 10 }}>
                                                            {setting.cv_info}
                                                        </li>
                                                    </ul>
                                                ) : null}
                                                {listemployees()}

                                                <Modal
                                                    show={modal_detail.status}
                                                    onHide={() =>
                                                        setModal_detail({ status: false, employee: [] })
                                                    }
                                                    dialogClassName="modal-default"
                                                    aria-labelledby="exampleModalCenterTitle"
                                                >
                                                    <div className="modal-dialog " style={{ margin: 0 }}>
                                                        <div className="modal-content">
                                                            <Modal.Header className="modal-header">
                                                                <div className="content-info-header-modal"
                                                                    style={{ textAlign: (i18n.language === "ar") ? "right" : "left" }}>
                                                                    <button
                                                                        type="button"
                                                                        className="close"
                                                                        onClick={() =>
                                                                            setModal_detail({
                                                                                status: false,
                                                                                employee: [],
                                                                            })
                                                                        }
                                                                    >
                                                                        <span aria-hidden="true">
                                                                            <i className="ri-close-line"></i>
                                                                        </span>
                                                                    </button>
                                                                    <h5
                                                                        className="modal-title"
                                                                        id="exampleModalCenterTitle"
                                                                    >
                                                                        {i18n.t("cvdata")}
                                                                    </h5>
                                                                </div>

                                                                <div className="content-save-info">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                            setEmployee(modal_detail.employee);
                                                                            let newkey = activeKey + 1;
                                                                            setactiveKey(newkey);
                                                                            if (newkey > maxKey) {
                                                                                setmaxKey(newkey);
                                                                            }

                                                                            setModal_detail({
                                                                                status: false,
                                                                                employee: [],
                                                                            });


                                                                        }}
                                                                        className="btn btn-primary button-reserv"
                                                                    >
                                                                        {i18n.t("choose")}
                                                                    </button>
                                                                </div>
                                                            </Modal.Header>
                                                            <Modal.Body className="custom-form-default modal-body">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="resum-worker">
                                                                            <div className="top-resum-worker">
                                                                                <div className="info-resum-worker">
                                                                                    <div className="photo-worker">
                                                                                        <img
                                                                                            src={
                                                                                                modal_detail.employee.image
                                                                                                    ? `${baseUrl}/${modal_detail.employee.image}`
                                                                                                    : "./img/no-image.png"
                                                                                            }
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="descr-resum-worker">
                                                                                        <span className="name-worker">
                                                                                            {modal_detail.employee.name}
                                                                                        </span>
                                                                                        <span className="number-worker">
                                                                                            {modal_detail.employee.id_number}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="content-worker">
                                                                                <div className="detailed-worker">
                                                                                    <span>{i18n.t("profession")}:</span>
                                                                                    {modal_detail.employee.category}{" "}
                                                                                </div>
                                                                                <div className="detailed-worker">
                                                                                    <span>{i18n.t("nationality")}:</span>
                                                                                    {
                                                                                        i18n.t(modal_detail.employee.nationality_value)
                                                                                    }
                                                                                </div>
                                                                                <div className="detailed-worker">
                                                                                    <span>{i18n.t("age")}:</span>
                                                                                    {moment().diff(
                                                                                        modal_detail.employee.birth_date,
                                                                                        "years"
                                                                                    )}{" "}
                                                                                    {i18n.t("year")}{" "}
                                                                                </div>
                                                                                <div className="detailed-worker">
                                                                                    <span>{i18n.t("sexe")}:</span>
                                                                                    {modal_detail.employee.sexe ===
                                                                                        "Female"
                                                                                        ? i18n.t("female")
                                                                                        : i18n.t("male")}
                                                                                </div>
                                                                                <div className="detailed-worker">
                                                                                    <span>{i18n.t("religion")}:</span>{" "}
                                                                                    {modal_detail.employee.religion ===
                                                                                        "muslim"
                                                                                        ? i18n.t("muslim")
                                                                                        : i18n.t("not_muslim")}{" "}
                                                                                </div>
                                                                                <div className="detailed-worker">
                                                                                    <span>{i18n.t("length")}:</span>
                                                                                    {modal_detail.employee.length}{i18n.t("m")}
                                                                                </div>
                                                                                <div className="detailed-worker">
                                                                                    <span>{i18n.t("weight")}:</span>
                                                                                    {
                                                                                        modal_detail.employee.weight
                                                                                    } {i18n.t("kg")}{" "}
                                                                                </div>
                                                                                <div className="detailed-worker">
                                                                                    <span>{i18n.t("skills")}:</span>
                                                                                    {modal_detail.employee.skills}
                                                                                </div>
                                                                                <div className="detailed-worker">
                                                                                    <span>{i18n.t("experience")}:</span>
                                                                                    {modal_detail.employee.experience}
                                                                                </div>
                                                                                <div className="detailed-worker">
                                                                                    <span>{i18n.t("last_work_adress")}:</span>
                                                                                    {
                                                                                        modal_detail.employee
                                                                                            .last_work_adress
                                                                                    }{" "}
                                                                                </div>
                                                                                {/* <div className="detailed-worker"><span>إجادة اللغة العربية:</span> {(modal_detail.employee.arab_speak === "yes") ? "نعم" : " لا"}</div>
                                      <div className="detailed-worker"><span>إجادة اللغة الانجليزية:</span> {(modal_detail.employee.english_speak === "yes") ? "نعم" : " لا"}</div>
                                    */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Modal.Body>
                                                        </div>
                                                    </div>
                                                </Modal>
                                            </div>
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            ) : (
                                <div className="portlet closed disabled">
                                    <div className="headerPortlet">
                                        <div className="num-steps">
                                            <span className="number">4</span>
                                            <h3 style={{ margin: 5 }}>{i18n.t("cv")}</h3>
                                        </div>
                                        <div className="arrow-left">
                                            <a href="#" disabled>
                                                <i className="ri-arrow-down-s-line"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {maxKey >= 1004 ? (
                                <div className="portlet" id="t1004">
                                    <Accordion.Toggle
                                        onClick={(e) => {
                                            setactiveKey(1004);

                                        }}
                                        className="headerPortlet"
                                        style={{ backgroundColor: "#fff" }}
                                        eventKey={1004}
                                    >
                                        <div className="num-steps">
                                            <span className="number">5</span>
                                            <h3 style={{ margin: 5 }}> {i18n.t("start_contract")}</h3>
                                        </div>
                                        <div className="arrow-left">
                                            <a href="#">
                                                {" "}
                                                <i className="ri-arrow-up-s-line"></i>
                                            </a>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={1004}>
                                        <div className="bodyPortlet">
                                            <div className="row custom-form-default mt-3 mb-5">
                                                {setting.datestart_info ? (
                                                    <ul className="col-12 ">
                                                        <li style={{ margin: 10 }}>
                                                            {setting.datestart_info}
                                                        </li>
                                                    </ul>
                                                ) : null}
                                                <div className="form-group col-lg-6 col-md-12 mt-3">
                                                    <span style={{ fontSize: 20, marginLeft: 5 }}>
                                                        {" "}
                                                        {i18n.t("start_contract")}:
                                                    </span>
                                                    <span
                                                        style={{
                                                            fontSize: 20,
                                                            color: "#000",
                                                            marginRight: 5,
                                                        }}
                                                    >
                                                        {date_receive}{" "}
                                                    </span>
                                                </div>

                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-lg-6 col-md-12">
                                                    <a
                                                        href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            let newkey = activeKey + 1;
                                                            setactiveKey(newkey);
                                                            if (newkey > maxKey) {
                                                                setmaxKey(newkey);
                                                            }

                                                        }}
                                                        className="next btn"
                                                    >
                                                        {i18n.t("next")}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            ) : (
                                <div className="portlet closed disabled">
                                    <div className="headerPortlet">
                                        <div className="num-steps">
                                            <span className="number">5</span>
                                            <h3 style={{ margin: 5 }}> {i18n.t("start_contract")}</h3>
                                        </div>
                                        <div className="arrow-left">
                                            <a href="#" disabled>
                                                <i className="ri-arrow-down-s-line"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {maxKey >= 1005 ? (
                                <div className="portlet" id="t1005">
                                    <Accordion.Toggle
                                        onClick={(e) => {
                                            setactiveKey(1005);
                                        }}
                                        className="headerPortlet"
                                        style={{ backgroundColor: "#fff" }}
                                        eventKey={1005}
                                    >
                                        <div className="num-steps">
                                            <span className="number">6</span>
                                            <h3 style={{ margin: 5 }}>{i18n.t("details_price")}</h3>
                                        </div>
                                        <div className="arrow-left">
                                            <a href="#">
                                                {" "}
                                                <i className="ri-arrow-up-s-line"></i>
                                            </a>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={1005}>
                                        <div className="bodyPortlet">
                                            <h4>{i18n.t("request_details")}</h4>
                                            <div className="row mt-3">
                                                {setting.details_info ? (
                                                    <ul className="col-12 ">
                                                        <li style={{ margin: 10 }}>
                                                            {setting.details_info}
                                                        </li>
                                                    </ul>
                                                ) : null}
                                                {selectedemployee()}
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="order-details">
                                                        <div className="orders">
                                                            <span className="title-order">
                                                                {i18n.t("receiving_method")}:
                                                            </span>
                                                            <span className="desc-order">
                                                                {transfert_type === "branche"
                                                                    ? i18n.t("branche")
                                                                    : i18n.t("transfert")}
                                                            </span>
                                                        </div>
                                                        <div className="orders">
                                                            <span
                                                                className="title-order">{i18n.t("contract_period")}:</span>
                                                            <span className="desc-order">
                                                                {duration.split("_")[1]}
                                                            </span>
                                                        </div>
                                                        <div className="orders">
                                                            <span className="title-order">
                                                                {" "}
                                                                {i18n.t("start_contract")}:
                                                            </span>
                                                            <span className="desc-order">
                                                                {date_receive}{" "}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4>{i18n.t("price_d")}</h4>
                                            <div className="row mt-3">
                                                <div className="col-lg-6 col-md-12">
                                                    {customTable()}
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-lg-6 col-md-12">
                                                    <a
                                                        href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            let newkey = activeKey + 1;
                                                            setactiveKey(newkey);
                                                            if (newkey > maxKey) {
                                                                setmaxKey(newkey);
                                                            }

                                                        }}
                                                        className="next btn"
                                                    >
                                                        {i18n.t("next")}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            ) : (
                                <div className="portlet closed disabled">
                                    <div className="headerPortlet">
                                        <div className="num-steps">
                                            <span className="number">6</span>
                                            <h3 style={{ margin: 5 }}>{i18n.t("details_price")}</h3>
                                        </div>
                                        <div className="arrow-left">
                                            <a href="#" disabled>
                                                <i className="ri-arrow-down-s-line"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {maxKey >= 1006 ? (
                                <div className="portlet" id="t1006">
                                    <Accordion.Toggle
                                        onClick={(e) => {
                                            setactiveKey(1006);


                                        }}
                                        className="headerPortlet"
                                        style={{ backgroundColor: "#fff" }}
                                        eventKey={1006}
                                    >
                                        <div className="num-steps">
                                            <span className="number">7</span>
                                            <h3 style={{ margin: 5 }}>{i18n.t("pay_complete")}</h3>
                                        </div>
                                        <div className="arrow-left">
                                            <a href="#">
                                                {" "}
                                                <i className="ri-arrow-up-s-line"></i>
                                            </a>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={1006}>
                                        <div className="bodyPortlet custom-form-default">
                                            {setting.payment_info ? (
                                                <ul className="col-12 ">
                                                    <li style={{ margin: 10 }}>
                                                        {setting.payment_info}
                                                    </li>
                                                </ul>
                                            ) : null}
                                            <div
                                                className={(vrequirement) ? "custom-control not-valid-form custom-checkbox mb-2" : "custom-control  custom-checkbox mb-2"}>
                                                <input
                                                    type="checkbox"
                                                    checked={requirement}
                                                    onChange={(e) => {
                                                        setRequirement(e.target.checked);
                                                        setvRequirement(false)
                                                    }}
                                                    className="custom-control-input"
                                                    id="customCheck1"
                                                />

                                                <label
                                                    className="custom-control-label"
                                                    for="customCheck1"
                                                >
                                                    {i18n.t("agree_terms")}
                                                </label>

                                            </div>
                                            <a
                                                href="#"
                                                onClick={() => {
                                                    setModal_rules(true);
                                                }}
                                                className="link-conditions mb-2"
                                            >
                                                {i18n.t("preview_condition")}
                                            </a>
                                            <div
                                                onChange={(e) => {
                                                    setPayment_type(e.target.id);
                                                    setvpayment_type(false)
                                                }}
                                                className={(vpayment_type) ? "row custom-form-default not-valid-form mt-4" : "row custom-form-default mt-4"}
                                            >

                                                <div className="col-md-12">
                                                    <div className="custom-control custom-radio mb-4">
                                                        <input
                                                            checked={payment_type === "bank_transfert"}
                                                            type="radio"
                                                            id="bank_transfert"
                                                            name="payment_type"
                                                            className="custom-control-input"
                                                            disabled={!setting.banktransfert_payment}
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="bank_transfert"
                                                        >
                                                            {i18n.t("banktransfer")}
                                                        </label>
                                                    </div>
                                                </div>


                                                <div className="col-md-12">
                                                    <div className="custom-control custom-radio mb-4">
                                                        <input
                                                            checked={payment_type === "payment"}
                                                            type="radio"
                                                            id="payment"
                                                            name="payment_type"
                                                            className="custom-control-input"
                                                            disabled={!setting.online_payment}
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="payment"
                                                        >
                                                            {i18n.t("onlinepayment")}
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>

                                            {payment_type === "bank_transfert" ? (
                                                <>
                                                    <div className="row">
                                                        {" "}
                                                        <div className="col-6">
                                                            {" "}
                                                            <ul>
                                                                <li>
                                                                    حساب شركة عبد المجيد عبد الرحمن الجريسي
                                                                    للاستقدام
                                                                </li>
                                                                <li>{i18n.t("rajhibank")} </li>
                                                                <li>{i18n.t("accountnumber")}: 504608010080654</li>
                                                                <li>{i18n.t("iban")}: SA5080000504608010080654</li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-6">
                                                            <img width="70%" src="./img/rajhi.png" alt="" />
                                                        </div>
                                                    </div>

                                                    <label className="mb-5">

                                                        <a style={{ backgroundColor: "#eaa22f", color: "#fff" }}
                                                            className="btn btn-primary btn-pay"> <i
                                                                style={{ fontSize: 25 }}
                                                                className="ri-file-upload-line"></i> {i18n.t("uploadfile")} :{" "} {base64_img.name}
                                                        </a>

                                                        <input
                                                            type="file"
                                                            style={{ display: "none" }}
                                                            size="10"
                                                            multiple={false}
                                                            onChange={_uploadFile}
                                                            accept="image/*,application/pdf"
                                                        />
                                                    </label>
                                                </>
                                            ) : null}

                                            <br />

                                            <Modal
                                                show={modal_rules}
                                                onHide={() => {
                                                    setModal_rules(false);
                                                }}
                                                dialogClassName="modal-default"
                                                aria-labelledby="exampleModalCenterTitle"
                                            >
                                                <div
                                                    className="modal-dialog "
                                                    style={{ margin: 0, wordWrap: "break-word" }}
                                                >
                                                    <div className="modal-content">
                                                        <Modal.Header className="modal-header">
                                                            <div className="content-info-header-modal">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        setModal_rules(false);
                                                                    }}
                                                                    className="close"
                                                                    data-dismiss="modal"
                                                                    aria-label="Close"
                                                                >
                                                                    <span aria-hidden="true">
                                                                        <i className="ri-close-line"></i>
                                                                    </span>
                                                                </button>
                                                                <h5
                                                                    className="modal-title"
                                                                    id="exampleModalCenterTitle"
                                                                >
                                                                    الشروط و الأحكام
                                                                </h5>
                                                            </div>

                                                            <div className="content-save-info">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        setRequirement(true);
                                                                        setModal_rules(false);
                                                                    }}
                                                                    className="btn btn-primary button-reserv"
                                                                >
                                                                    موافق
                                                                </button>
                                                            </div>
                                                        </Modal.Header>
                                                        <Modal.Body className="custom-form-default modal-body">
                                                            <p style={{ wordWrap: "break-word" }}>
                                                                حيث أن شركة عبدالمجيد عبدالرحمن الجريسي للموارد
                                                                البشريةمقدم الخدمة (الطرف الأول) وعنوانه الرياض
                                                                ص.ب 54108 الرمز البريدي 11514 هاتف 920000239
                                                                فاكس 0114021458 البريد الالكتروني: info@jr.sa
                                                                مرخص له بتقديم الخدمات العمالة المنزلية ولديه
                                                                الخبرات والإمكانيات اللازمة لتوفير خدمات العمالة
                                                                المنزلية وذلك وفق الأنظمة واللوائح والتعليمات
                                                                المعمول بها في المملكة العربية السعودية وبما لا
                                                                يتعارض مع أحكام الشريعة الإسلامية. ولحاجة
                                                                العميل(الطرف الثاني) الموضح بياناته في "تسجيل
                                                                الدخول" لخدمات العمالة المنزلية المقدمة من الطرف
                                                                الأول فقد إتفق الطرفان وهما بكامل أهليتهما
                                                                المعتبرة قانونياً على ما يلي:-
                                                                <br />
                                                                <strong style={{ color: "#1fc397" }}>
                                                                    أولاً – يقصد بالألفاظ الآتية أينما وردت في هذا
                                                                    العقد المعاني الموضحة أمامها مالم يقتضي سياق
                                                                    النص خلاف ذلك:-
                                                                </strong>
                                                                <br />
                                                                • مقدم الخدمة : شركة عبدالمجيد عبدالرحمن الجريسي
                                                                للموارد البشرية والمرخص لها من وزارة الموارد
                                                                البشرية والتنمية الاجتماعية
                                                                <br />
                                                                • العامل : عامل الخدمة المنزلية المصنف للعمل
                                                                المنزلي وفقا للمهنة التي يعمل بها(من الجنسين)
                                                                <br />
                                                                • الوزارة : وزارة الموارد البشرية والتنمية
                                                                الاجتماعية
                                                                <br />
                                                                • العميل : المستفيد من خدمات عامل الخدمة
                                                                المنزلية
                                                                <br />
                                                                <strong style={{ color: "#1fc397" }}>
                                                                    ثانيا - موضوع العقد ومدته
                                                                </strong>
                                                                <br />
                                                                إتفق الطرفان على أن يقوم الطرف الأول بتوفير عامل
                                                                منزلي كما هو موضح في "تفاصيل الطلب"، ومدة هذا
                                                                العقد وبدايته ونهايتهكما هو موضح في "تفاصيل
                                                                الطلب"،قابلة للتجديد بعد السداد لمدةٍ أومددٍ
                                                                مماثلة بموجب إشعار مكتوب عبر قنوات التواصل
                                                                المذكورة قبل نهاية مدة العقد.
                                                                <br />
                                                                <strong style={{ color: "#1fc397" }}>
                                                                    ثالثاً - قيمة العقد :-
                                                                </strong>
                                                                <br />
                                                                1- بعدالموافقة على الشروط والأحكام
                                                                الكترونيًايدفع العميللمقدم الخدمة كامل قيمة
                                                                الخدمة وتبدأ مدة احتساب الخدمة كما هو وارد
                                                                في"تفاصيل الطلب".
                                                                <br />
                                                                <strong style={{ color: "#1fc397" }}>
                                                                    رابعاً - إلتزامات مقدم الخدمة : -
                                                                </strong>
                                                                <br />
                                                                1- تقديم خدمات العامل المذكور للعميل.
                                                                <br />
                                                                2- إيقاف إحتساب قيمة الخدمة من تاريخ إرجاع
                                                                العامل أوإبلاغ العميل عن تغيب العامل.
                                                                <br />
                                                                3- عدم تحمل العميل اي رسوم تجاه تغيب العامل مالم
                                                                يثبت عدم صحة بلاغ التغيب المقدم من العميل ، وفي
                                                                هذالحالة يحق لمقدم الخدمة تقديم بلاغ ضد العميل
                                                                لدى الجهات المختصة .
                                                                <br />
                                                                4- ضمان اللياقة الطبية للعامل وخلوه من الأمراض
                                                                المعدية والمزمنة والعاهات الجسيمة وكل مايكون من
                                                                شأنه عدم القدرة على تنفيذ العمل المطلوب منه،
                                                                وتقديم الرعاية الطبية للعامل أثناء سريان هذا
                                                                العقد بموجب تأمين صحي طبقاً لنظام الضمان الصحي
                                                                التعاوني.
                                                                <br />
                                                                5- دفع راتب العامل الشهري مباشرة له من خلال
                                                                تحويل بنكي على الحساب البنكي للعامل ولا يتحمل
                                                                مقدم الخدمة أي مبالغ مصروفة من قبل العميل للعامل
                                                                بشكل مباشر أو غير مباشر.
                                                                <br />
                                                                6- إذا كان العامل بمهنة(سائق) فيجب أن يحمل رخصة
                                                                قيادة سعودية سارية المفعول. وربط السائق في نظام
                                                                تم لعدم الإيقاع بالمخالفات المرورية. وفي حالة
                                                                عدم الربط لا يحق للعميل مطالبة مقدم الخدمة بأي
                                                                مخالفات تقع على السائق.
                                                                <br />
                                                                7- تقديم عامل بديل عن العامل المقدم خدماته
                                                                للقيام بنفس العمل الذي طلب من أجله وذلك بناء على
                                                                رغبة العميل وخلال مدة لاتزيد عن ثلاثون يوما،
                                                                وذلك في الحالات الاتية:-
                                                                <br />
                                                                • التغيب عن العمل
                                                                <br />
                                                                • رفض العمل بسبب لايعود الى العميل
                                                                <br />
                                                                • إصابة العامل بمرض لايمكنه من اداء عمله
                                                                <br />
                                                                • عدم إجادة المهنة
                                                                <br />
                                                                • وفاة عامل الخدمة المنزلية الوفاة الطبيعية
                                                                <br />
                                                                • إذا كانت العاملة حامل فبل تسليمها للعميل
                                                                <br />
                                                                <strong style={{ color: "#1fc397" }}>
                                                                    خامساً - التزامات العميل :-
                                                                </strong>
                                                                <br />
                                                                1- سداد التكلفة المتفق عليها في "تفاصيل الطلب"،
                                                                أو قبل تجديد مدة العقد بثلاثون يوماً.
                                                                <br />
                                                                2- إستلام وتسليم العامل كما جاء في "تفاصيل
                                                                الطلب"، وفي حال تأخر العميل عن استلام العامل
                                                                فيكون متحملا مبلغ (100 ريال) فقط مائة ريال تكلفة
                                                                تمديد فترة بقاء العامل لدي مزود الخدمة مع احتساب
                                                                قيمة الخدمة اليومية.
                                                                <br />
                                                                3- أن يعامل العامل بالإحترام اللائق، وأن يمتنع
                                                                عن كل قول، أو فعل يمس كرامته، أو دينه، وتوفير
                                                                السكن المناسب للعامل المقدم خدماته خلال مدة
                                                                العقد
                                                                <br />
                                                                4- تمكين العامل للوصول إلى أقرب فرع بنك أو جهاز
                                                                صراف آلي لإستلام مرتبه الشهري ومساعدته لتحويل
                                                                أية مبالغ لذويه.
                                                                <br />
                                                                5- أن يكون مسئولا عن أي تجاوزات تلحق أضراراً
                                                                بالعامل في مواجهة مقدم الخدمة والعامل.
                                                                <br />
                                                                6- تبليغ مقدم الخدمة بتغيب العامل بمدة لاتزيد عن
                                                                24 ساعة من تاريخ تغيبه أوفي حالة الإصابة أو
                                                                العجز أو عدم الوفاء بالعمل.
                                                                <br />
                                                                7- عدم تشغيل العامل في أعمال تختلف عن المهنة
                                                                المرخص له بها سواء بطريقة مباشرة أو غير مباشرة.
                                                                <br />
                                                                8- يشترط اذا كانت مهنة العامل (سائق) أن تكون
                                                                السيارة المسلمة له مؤمن عليها تأمين ضد الغير علي
                                                                الأقل.
                                                                <br />
                                                                9- توفير وسيلة نقل العامل إلى أقرب مركز طبي
                                                                معتمد في شبكة المراكز الطبية لشركة التأمين
                                                                المعتمدة لدى مقدم الخدمة إذا كان العامل في حاجة
                                                                إلى الرعاية الطبية وفي حالة عدم توافر مراكز طبية
                                                                معتمدة لدى شركة التأمين يتم علاج العامل من قبل
                                                                العميل بما يتوافق مع قيمة بوليصة التأمين وطبقاً
                                                                للدرجة المخصصة للعامل بحيث يتم تقديم أصول
                                                                الفواتير والتقارير الطبية لمقدم الخدمة خلال (30)
                                                                يوم من تاريخ الفاتورة لتقديمها لشركة التأمين
                                                                لطلب التعويض بحسب بوليصة شركة التأمين.
                                                                <br />
                                                                10- إعادة العامل عند إنتهاء مدة تقديم خدماته
                                                                لمقر الطرف الأول،وفي حال عدم التزام العميل بذلك
                                                                فإنه ملزم بسداد مبلغ وقدره (100ريال سعودي) مائة
                                                                ريال سعودي عن كل يوم تأخير بالإضافة الى قيمة
                                                                التكلفة اليومية للعامل المقدمة خدماته.
                                                                <br />
                                                                11- تأمين الإعاشة.
                                                                <br />
                                                                12- يحق للطرف الأول إذا تأخر الطرف الثاني عن
                                                                السداد إدراجه في الشركة السعودية للمعلومات
                                                                الإئتمانية (سمه).
                                                                <br />
                                                                <strong style={{ color: "#1fc397" }}>
                                                                    سادسًا : حماية خصوصية المعلومات :-
                                                                </strong>
                                                                <br />
                                                                يجب على مقدم الخدمة الحفاظ على معلوماتالعملاء،
                                                                وعدم إفشاء أو استخدام أي معلومات تتعلق بالعميل
                                                                مالم يتم طلبها من الجهات الرسمية و يحق للطرف
                                                                الأول تزويد الشركة السعودية للمعلومات الإئتمانية
                                                                (سمه) بمعلومات الطرف الثاني.
                                                                <br />
                                                                <strong style={{ color: "#1fc397" }}>
                                                                    سابعًا : معالجة الشكاوى:-
                                                                </strong>
                                                                <br />
                                                                1- في حالة تقديم شكوى من العميل بخصوص العامل او
                                                                الخدمة المقدمة على ايميل مقدم الخدمة info@jr.sa
                                                                او بالاتصال الهاتفي على رقم (920000239)سيتم
                                                                استقبال الشكوى والعمل على حلها خلال مدة لاتزيد
                                                                عن (10) أيام عشرة ايام عمل كحد أقصى.
                                                                <br />
                                                                <strong style={{ color: "#1fc397" }}>
                                                                    ثامنًا : الإخطارات :-
                                                                </strong>
                                                                <br />
                                                                تتم الإخطارات والإشعارات بين الطرفين كتابةً عن
                                                                طريق الإستلام المباشر أو البريد المسجل أو البريد
                                                                الممتاز أو بريد واصل أو البريد إلكتروني أو
                                                                برسائل الجوال، وتـوجه الإخطارات علـى العـنوان
                                                                المسجل، ويلتزم كلا الطرفين بإخطار الطرف الآخر
                                                                كتابياً في حال قيامه بتغيير عنوانه أو مكان
                                                                إقامته أو أي من أرقام الإتصال الخاصة به المسجلة
                                                                بهذا العقد وإلا أعتبر العنوان المدون أعلاه هو
                                                                المعمول به نظاماً دون غيره.
                                                                <br />
                                                                <strong style={{ color: "#1fc397" }}>
                                                                    تاسعًا: أحكام عامة:-
                                                                </strong>
                                                                <br />
                                                                1- حق العميل بإلغاء العقد قبل الحصول على الخدمة
                                                                واسترداد التكاليف المدفوعة مخصوما منها تكلفة
                                                                إلغاء العقد على أن لا تتجاوز تكلفة الإلغاء 1%
                                                                واحد بالمائة من من إجمالي التكلفة المدفوعة.
                                                                <br />
                                                                2- لايجوز للعميل القيام بإعادة تقديم خدمات
                                                                العامل لأي طرف آخر سواء بطريقة مباشرة أو غير
                                                                مباشرة.
                                                                <br />
                                                                3- أي شروط إضافية تتعارض مع نظام العمل أو لائحته
                                                                أو القرارات الصادرة تنفيذاً له تعتبر باطلة.
                                                                <br />
                                                                <strong style={{ color: "#1fc397" }}>
                                                                    عاشرًا: المنــــــــازعات والــــــــخلافـات:-{" "}
                                                                </strong>
                                                                <br />
                                                                في حالة حدوث خلاف بين الطرفين يتم حله وديًا لدى
                                                                إدارة الموارد البشرية بالطرف الأول، وفي حال عدم
                                                                التوصل لحل خلال (10) أيام يتم الرفع للمحكمة
                                                                المختصة في مدينة الرياض.
                                                                <br />
                                                                <strong style={{ color: "#1fc397" }}>
                                                                    الحادي عشر: إنتهاء العقد أو فسخه:-
                                                                </strong>
                                                                <br />
                                                                1- ينتهي العقد بإنتهاء مدته المتفق عليها، ويتم
                                                                فسخه في حال الإخلال بأحد أحكامه وشروطه.
                                                                <br />
                                                                2- في حال تعذر على الطرف الأول تقديم الخدمة لأي
                                                                سبب كان بعد التعاقد يتم فسخ العقد واسترجاع
                                                                القيمة المدفوعة خلال 5 أيام عمل.
                                                                <br />
                                                                <strong style={{ color: "#1fc397" }}>
                                                                    الثاني عشر: نسخ العقد:-
                                                                </strong>
                                                                <br />
                                                                تعتبر النسخ الالكترونية صحيحة ونافذة وملزمة
                                                                للطرفين بمجرد موافقة الطرف الثاني على الأحكام
                                                                والشروط الكترونيًا.
                                                            </p>
                                                        </Modal.Body>
                                                    </div>
                                                </div>
                                            </Modal>

                                            <div className="row mt-3 mb-3">
                                                <div className="col-md-12 d-flex justify-content-center">
                                                    <button
                                                        disabled={
                                                            loading
                                                        }
                                                        type="button"
                                                        onClick={make_order}
                                                        className="btn btn-primary btn-pay"
                                                    >
                                                        {loading ? (
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        ) : null}
                                                        {i18n.t("sendrequest")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            ) : (
                                <div className="portlet closed disabled">
                                    <div className="headerPortlet">
                                        <div className="num-steps">
                                            <span className="number">7</span>
                                            <h3 style={{ margin: 5 }}>{i18n.t("pay_complete")}</h3>
                                        </div>
                                        <div className="arrow-left">
                                            <a href="#" disabled>
                                                <i className="ri-arrow-down-s-line"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Accordion>
                    </div>
                </main>
            ) : null}
            {window === "login" ? (
                <Login callbackwindow={callbackwindow} callbackcin={callbackcin} />
            ) : null}
            {window === "about" ? (
                <About />
            ) : null}


            {window === "forgotpassword" ? (
                <Forgotpassword callbackwindow={callbackwindow} callbackcin={callbackcin} />
            ) : null}
            {window === "resetpassword" ? (
                <Resetpassword callbackwindow={callbackwindow} cin={cin} pass_code={pass_code} />
            ) : null}
            {window === "activation" ? (
                <Activation callbackwindow={callbackwindow} callbackpass={callbackpass} cin={cin} />
            ) : null}
            {window === "myactivation" ? (
                <Myactivation callbackwindow={callbackwindow} callbackpass={callbackpass} cin={cin} />
            ) : null}
            {window === "register" ? (
                <Register callbackwindow={callbackwindow} callbackcin={callbackcin} />
            ) : null}
            {window === "contracts" ? (
                <Contracts
                    successorder={successorder}
                    callbackwindow={callbackwindow}
                />
            ) : null}
            {window === "contract" ? (
                <Contract successorder={successorder} callbackwindow={callbackwindow} />
            ) : null}

            {window === "profile" ? <Profile /> : null}

            <footer>


                <div className="container-fluid footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-5">
                                <div className="zone-logo-social-network">
                                    <div className="logo-footer">
                                        <img src="//jr.sa/images/logo-gray.png" alt="Al Jeraisy Logo - Grayscale" />
                                    </div>
                                    <div className="social-network">
                                        <a href="//www.facebook.com/%D8%B4%D8%B1%D9%83%D8%A9-%D8%B9%D8%A8%D8%AF%D8%A7%D9%84%D9%85%D8%AC%D9%8A%D8%AF-%D8%B9%D8%A8%D8%AF%D8%A7%D9%84%D8%B1%D8%AD%D9%85%D9%86-%D8%A7%D9%84%D8%AC%D8%B1%D9%8A%D8%B3%D9%8A-%D9%84%D9%84%D9%85%D9%88%D8%A7%D8%B1%D8%AF-%D8%A7%D9%84%D8%A8%D8%B4%D8%B1%D9%8A%D8%A9-101741904813841"
                                            target="_blank"><img src="./img/icon-facebook.png" alt="" /></a>
                                        <a href="//www.instagram.com/jeraisyhr/?hl=en" target="_blank"><img
                                            src="./img/icon-instagram.png" alt="" /></a>
                                        <a href="//www.linkedin.com/company/aljeraisy-recruitment/" target="_blank"><img
                                            src="./img/icon-linkedIn.png" alt="" /></a>
                                        <a href="//twitter.com/JeraisyHR" target="_blank"><img
                                            src="./img/icon-twitter.png" alt="" /></a>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-3 col-md-2">
                                <h3>الشركة</h3>
                                <ul>
                                    <li><a href="//jr.sa/about">من نحن</a></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-3">
                                <h3>تواصل معنا</h3>
                                <ul>
                                    <li><i className="icon-marker"></i> المملكة العربية السعودية</li>
                                    <li>920000239<i className="icon-phone"></i></li>
                                    <li><a href="mailto:info@jr.sa">info@jr.sa<i className="icon-send"></i></a></li>
                                </ul>
                            </div>

                        </div>


                    </div>
                </div>


                <div className="container-fluid footer-copyright">
                    <div className="container d-flex justify-content-center align-items-center">
                        <span>Copyright 2020 © | </span>
                        شركة عبد المجيد عبد الرحمن الجريسي للموارد البشرية
                    </div>
                </div>

                <div className="footer-mobile">
                    <ul>
                        <li>
                            <a
                                href="#"
                                onClick={() => {
                                    setwindow("main");
                                    setsuccessorder(false);
                                }}
                                className={window === "main" ? "active" : ""}
                            >
                                <i className="icon-home"></i>
                                <span>{i18n.t("home")}</span>
                            </a>
                        </li>
                        {currentUser === null ? (
                            <>
                                <li>
                                    <a
                                        href="#"
                                        onClick={() => {
                                            setwindow("register");
                                            setsuccessorder(false);
                                        }}
                                        className={window === "register" ? "active" : ""}
                                    >
                                        <i className="icon-add"></i>
                                        <span>{i18n.t("register")}</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        onClick={() => {
                                            setwindow("login");

                                            setsuccessorder(false);
                                        }}
                                        className={window === "login" ? "active" : ""}
                                    >
                                        <i className="icon-user"></i>
                                        <span>{i18n.t("login")}</span>
                                    </a>
                                </li>
                            </>
                        ) : null}

                        {currentUser !== null ? (
                            <li>
                                <a
                                    href="#"
                                    className={window === "contracts" ? "active" : ""}
                                    onClick={() => {
                                        setwindow("contracts");
                                        setsuccessorder(false);
                                    }}
                                >
                                    <i className="icon-contract"></i>
                                    <span>{i18n.t("contracts")}</span>
                                </a>
                            </li>
                        ) : null}
                        {currentUser !== null ? (
                            <li>
                                <a
                                    href="#"
                                    className={window === "profile" ? "active" : ""}
                                    onClick={() => {
                                        setwindow("profile");

                                        setsuccessorder(false);
                                    }}
                                >
                                    <i className="icon-user"></i>
                                    <span>{i18n.t("profile")}</span>
                                </a>
                            </li>
                        ) : null}
                    </ul>
                </div>
            </footer>
        </div>
    );
}

export default App;