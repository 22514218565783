import React, {useEffect, useState} from "react";
import {Alert, Form} from "react-bootstrap";
import {authenticationService} from './authentification.service';
import {useTranslation} from "react-i18next";

function setQueryStringWithoutPageReload(qsValue) {
    const newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        qsValue;

    window.history.pushState({path: newurl}, "", newurl);
}

function getSearchParams(k) {
    var p = {};
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (s, k, v) {
        p[k] = v;
    });
    return k ? p[k] : p;
}

function Resetpassword(props) {
    const [password, setPassword] = useState('');
    const [passwordv, setPasswordv] = useState(false);
    const [confirmpassword, setConfirmpassword] = useState('');
    const [confirmpasswordv, setConfirmpasswordv] = useState(false);
    const [messagealert, setMessagealert] = useState("");
    const [code, setcode] = useState("");
    const {t, i18n} = useTranslation();
    useEffect(() => {
        let resetpassword = getSearchParams("resetpassword");

        if (resetpassword) {
            setcode(resetpassword)
            setQueryStringWithoutPageReload("")
        }

    }, [])

    const submitVerificationcode = (e) => {
        e.preventDefault();
        let validation = true
        let confirm = true
        if (password.length < 6) {
            setPasswordv(true)
            validation = false
        }
        if (confirmpassword !== password) {
            setPasswordv(true)
            setConfirmpasswordv(true)
            confirm = false

        }

        if (validation && confirm) authenticationService.resetpassword(props.cin, props.pass_code, password).then((v) => {
            setQueryStringWithoutPageReload("?reset=true")
            props.callbackwindow('login')
        }).catch((e) => {
            console.log(e)
        })
        else {
            if (!validation) {
                setMessagealert(t("wrongfield"))
            } else {
                setMessagealert(t("passwordnotmatch"))
            }
        }
        return false
    }

    return (<main role="login">

            <div className="container-fluid banner d-flex justify-content-center align-items-center">
                <div className="container">
                    <h2>{t("findservice")}</h2>
                </div>
            </div>
            <div className="container">
                <div className="row d-flex justify-content-center mt-4">
                    <div className="col-lg-6 col-md-10 text-center">
                        <h3> {t("setnewpassword")}</h3>
                        <div className="login custom-form-default mt-4">

                            <Form className="row" onSubmit={submitVerificationcode}>
                                {(messagealert.length > 0) ?
                                    <Alert style={{width: "100%"}} variant="danger">{messagealert}</Alert> : null}

                                <div
                                    className={(passwordv) ? "form-group not-valid-form col-md-12 mt-2 mb-4" : "form-group col-md-12 mt-2 mb-4"}>
                                    <input type="password" onChange={(e) => {
                                        setPassword(e.target.value)
                                        setPasswordv(false)
                                        setMessagealert('')
                                    }} className="form-control" id="input16" placeholder={t("enterpassword")}/>
                                    <span className="focus-border"></span>
                                </div>
                                <div
                                    className={(confirmpasswordv) ? "form-group not-valid-form col-md-12 mt-2 mb-4" : "form-group col-md-12 mt-2 mb-4"}>
                                    <input type="password" onChange={(e) => {
                                        setConfirmpassword(e.target.value)
                                        setConfirmpasswordv(false)
                                        setMessagealert('')
                                    }} className="form-control" id="input17" placeholder={t("confirmpassword")}/>
                                    <span className="focus-border"></span>
                                </div>

                                <div className="col-md-12 mt-2 mb-4">
                                    <button type="submit"
                                            className="btn btn-primary btn-login w-100  justify-content-center">{t("save")}</button>
                                </div>
                            </Form>


                        </div>
                    </div>
                </div>
            </div>

        </main>
    )
}

export default Resetpassword;
