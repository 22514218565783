import {BehaviorSubject} from "rxjs";
import {baseUrl} from "./config";
import {authHeader} from "./auth_header";

const currentUserSubject = new BehaviorSubject(
    JSON.parse(localStorage.getItem("currentUser"))
);

export const authenticationService = {
    login,
    logout,
    order,
    extend,
    get_contracts,
    get_contract,
    active,
    changephone,
    resend,
    register,
    get_profile,
    resetpassword,
    update_profile,
    submit_request,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value;
    },
};

function login(cin, password) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({cin, password}),
    };

    return fetch(`${baseUrl}/api/login`, requestOptions)
        .then((response) => {
            return response.text().then((text) => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                        authenticationService.logout();
                        //location.reload(true);
                    }

                    const error = (data && data.message) || response.statusText;
                    return Promise.reject({message: error, code: response.status});
                }

                return data;
            });
        })
        .then((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(user.data));
            currentUserSubject.next(user.data);

            return user.data;
        });
}

function register(
    cin,
    password,
    name,
    lastname,
    nationality,
    social_state,
    sexe,
    phone,
    phone2,
    email
) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
            cin,
            password,
            name,
            lastname,
            nationality,
            social_state,
            sexe,
            phone,
            phone2,
            email,
        }),
    };

    return fetch(`${baseUrl}/api/customers`, requestOptions)
        .then((response) => {
            return response.text().then((text) => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                        authenticationService.logout();
                        //location.reload(true);
                    }

                    const error = (data && data.message) || response.statusText;
                    return Promise.reject({message: error, code: response.status});
                }

                return data;
            });
        })
        .then((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return user.data;
        });
}

function active(cin, code) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({cin, code}),
    };

    return fetch(`${baseUrl}/api/active`, requestOptions)
        .then((response) => {
            return response.text().then((text) => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                        authenticationService.logout();
                        //location.reload(true);
                    }

                    const error = (data && data.message) || response.statusText;
                    return Promise.reject({message: error, code: response.status});
                }

                return data;
            });
        })
        .then((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return user.data;
        });
}

function resend(cin) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({cin}),
    };

    return fetch(`${baseUrl}/api/resend`, requestOptions)
        .then((response) => {
            return response.text().then((text) => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                        authenticationService.logout();
                        //location.reload(true);
                    }

                    const error = (data && data.message) || response.statusText;
                    return Promise.reject({message: error, code: response.status});
                }
                console.log(data);
                return data;
            });
        })
        .then((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return user.data;
        });
}

function resetpassword(cin, pass_code, password) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({cin, pass_code, password}),
    };

    return fetch(`${baseUrl}/api/reset`, requestOptions)
        .then((response) => {
            return response.text().then((text) => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                        authenticationService.logout();
                        //location.reload(true);
                    }

                    const error = (data && data.message) || response.statusText;
                    return Promise.reject({message: error, code: response.status});
                }

                return data;
            });
        })
        .then((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return user.data;
        });
}

function changephone(cin, phone) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({cin, phone}),
    };

    return fetch(`${baseUrl}/api/changephone`, requestOptions)
        .then((response) => {
            return response.text().then((text) => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                        authenticationService.logout();
                        //location.reload(true);
                    }

                    const error = (data && data.message) || response.statusText;
                    return Promise.reject({message: error, code: response.status});
                }

                return data;
            });
        })
        .then((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return user.data;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    currentUserSubject.next(null);
}

function order(
    employee_id,
    pack_id,
    pack_duration,
    transfert_type,
    price,
    map_position,
    manual_payment,
    adress,
    payement_type,
    start_at,
    image_payement
) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", ...authHeader()},
        body: JSON.stringify({
            employee_id,
            pack_id,
            pack_duration,
            transfert_type,
            price,
            map_position,
            manual_payment,
            adress,
            payement_type,
            start_at,
            image_payement,
        }),
    };

    return fetch(`${baseUrl}/api/contract/create`, requestOptions)
        .then((response) => {
            return response.text().then((text) => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                        authenticationService.logout();
                        //location.reload(true);
                    }

                    const error = (data && data.message) || response.statusText;
                    console.log(data);
                    return Promise.reject({error, code: response.status});
                }

                return data;
            });
        })
        .then((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            console.log(user);
            return user.data;
        });
}

function extend(
    price,
    contract_id,
    payement_type,
    pack_duration,
    image_payement
) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", ...authHeader()},
        body: JSON.stringify({
            price,
            contract_id,
            payement_type,
            pack_duration,
            image_payement,
        }),
    };

    return fetch(`${baseUrl}/api/contract/extension`, requestOptions)
        .then((response) => {
            return response.text().then((text) => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                        authenticationService.logout();
                        //location.reload(true);
                    }

                    const error = (data && data.message) || response.statusText;
                    console.log(data);
                    return Promise.reject({error, code: response.status});
                }

                return data;
            });
        })
        .then((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            console.log(user);
            return user.data;
        });
}

function submit_request(contract_id, type, description) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", ...authHeader()},
        body: JSON.stringify({contract_id, type, description}),
    };

    return fetch(`${baseUrl}/api/requestchanges`, requestOptions)
        .then((response) => {
            return response.text().then((text) => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                        authenticationService.logout();
                        //location.reload(true);
                    }

                    const error = (data && data.message) || response.statusText;
                    console.log(data);
                    return Promise.reject({error, code: response.status});
                }

                return data;
            });
        })
        .then((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            console.log(user);
            return user.data;
        });
}

function get_contracts() {
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", ...authHeader()},
    };

    return fetch(`${baseUrl}/api/me/contracts`, requestOptions)
        .then((response) => {
            return response.text().then((text) => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                        authenticationService.logout();
                        //location.reload(true);
                    }

                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        })
        .then((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            console.log(user.data);
            return user.data;
        });
}

function get_contract(id) {
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", ...authHeader()},
    };

    return fetch(`${baseUrl}/api/contract/${id}`, requestOptions)
        .then((response) => {
            console.log(response);
            return response.text().then((text) => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                        authenticationService.logout();
                        //location.reload(true);
                    }

                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        })
        .then((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            console.log(user.data);
            return user.data;
        });
}

function get_profile() {
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", ...authHeader()},
    };

    return fetch(`${baseUrl}/api/me/profile`, requestOptions)
        .then((response) => {
            return response.text().then((text) => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                        authenticationService.logout();
                        //location.reload(true);
                    }

                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        })
        .then((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            console.log(user.data);
            return user.data;
        });
}

function update_profile(
    password,
    name,
    lastname,
    nationality,
    social_state,
    sexe,
    phone2
) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", ...authHeader()},
        body: JSON.stringify({
            password,
            name,
            lastname,
            nationality,
            social_state,
            sexe,
            phone2,
        }),
    };

    return fetch(`${baseUrl}/api/profile`, requestOptions)
        .then((response) => {
            return response.text().then((text) => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                        authenticationService.logout();
                        //location.reload(true);
                    }

                    const error = (data && data.message) || response.statusText;
                    return Promise.reject({message: error, code: response.status});
                }

                return data;
            });
        })
        .then((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return user.data;
        });
}
