import React from "react";

function About() {
    return (
        <main role="services">
            <div className="container-fluid banner d-flex justify-content-center align-items-center">
                <div className="container">
                    <h2>ابحث عن خدمة العمالة المناسبة لك</h2>
                </div>
            </div>

            <div className="container">
                <div className="row d-flex justify-content-center mt-4">
                    <div className="col-md-12 text-center">
                        <h2 className="title-page">من نحن</h2>
                    </div>
                </div>
                <div className="row d-flex justify-content-center mt-4">
                    <div className="col-md-10">
                        <div className="content-internal-page">
                            <h3 className="title-internal-page">ﻋن اﻟﺷرﻛﺔ </h3>
                            <p className="description">
                                ﺷرﻛﺔ ﻋﺑداﻟﻣﺟﯾد ﻋﺑداﻟرﺣﻣن اﻟﺟرﯾﺳﻲ ﻟﻠﻣوارد اﻟﺑﺷرﯾﺔ ﺷرﻛﺔ ﺳﻌودﯾﺔ
                                ﻣﺳﺎھﻣﺔ ﻣﻘﻔﻠﺔ ﺑرأس ﻣﺎل 100,000,000 ﷼ ﺳﻌودي، ﻣﺗﺧﺻﺻﺔ ﻓﻲ ﺗﻠﺑﯾﺔ
                                اﺣﺗﯾﺎﺟﺎت اﻟﺳوق اﻟﻣﺣﻠﯾﺔ ﻣن اﻟﻛوادر اﻟﺑﺷرﯾﺔ اﻟﻣؤھﻠﺔ واﻟﻣدرﺑﺔ
                                ﻟﻠﻘطﺎﻋﯾن اﻟﻌﺎم واﻟﺧﺎص وﻗطﺎع اﻷﻓراد ﺑﻣﺧﺗﻠف اﻟﺗﺧﺻﺻﺎت وﻣن ﻣﺧﺗﻠف
                                اﻟﺟﻧﺳﯾﺎت، ﻛذﻟك ﺗﻘدم ﺧدﻣﺎت اﻟﺗوﺳط ﻓﻲ إﻧﮭﺎء ﺟﻣﯾﻊ اﻹﺟراءات اﻟﻧظﺎﻣﯾﺔ
                                ﻻﺳﺗﻘدام اﻟﻌﻣﺎﻟﺔ ﻟﺻﺎﻟﺢ اﻷﻓراد وﻓﻖ اﻷﻧظﻣﺔ واﻟﻘواﻧﯾن اﻟﻧﺎﻓذة، وﺗؤدي
                                اﻟﺷرﻛﺔ ﺧدﻣﺎﺗﮭﺎ ﻣن ﺧﻼل ﺷﺑﻛﺔ واﺳﻌﺔ ﻣن اﻟﻔروع ﺑﺎﻟﻣﻣﻠﻛﺔ اﻟﻌرﺑﯾﺔ
                                اﻟﺳﻌودﯾﺔ.
                            </p>
                            <h3 className="title-internal-page">ﻣﻣﯾزات اﻟﺧدﻣﺔ</h3>
                            <ul className="defaultList">
                                <li>
                                    <i className="ri-arrow-drop-left-line"></i> اﻟرواﺗب اﻟﺷﮭرﯾﺔ
                                    واﻟرﺳوم اﻟﺣﻛوﻣﯾﺔ ﻣدﻓوﻋﺔ ﺑﺎﻟﻛﺎﻣل{" "}
                                </li>
                                <li>
                                    <i className="ri-arrow-drop-left-line"></i> اﺧﺗﯾﺎر ﺑﻧﻔﺳك{" "}
                                </li>
                                <li>
                                    <i className="ri-arrow-drop-left-line"></i> ﺟﻧﺳﯾﺎت ﻣﺗﻧوﻋﺔ
                                    وﺧﺑرات ﻣﺧﺗﻠﻔﺔ{" "}
                                </li>
                                <li>
                                    <i className="ri-arrow-drop-left-line"></i> ﺗوﻓﯾر رﺳوم
                                    اﻻﺳﺗﻘدام{" "}
                                </li>
                                <li>
                                    <i className="ri-arrow-drop-left-line"></i> اﻟﺗﺄﻣﯾن واﻟﻔﺣص
                                    اﻟطﺑﻲ{" "}
                                </li>
                                <li>
                                    <i className="ri-arrow-drop-left-line"></i> اﻟﺗﻌوﯾض واﻻﺳﺗﺑدال{" "}
                                </li>
                                <li>
                                    <i className="ri-arrow-drop-left-line"></i> ﻓﺗرات ﺗﻌﺎﻗد ﻣرﻧﺔ{" "}
                                </li>
                                <li>
                                    <i className="ri-arrow-drop-left-line"></i> ﻣﺳﺗﺣﻘﺎت ﻧﮭﺎﯾﺔ
                                    اﻟﺧدﻣﺔ{" "}
                                </li>
                                <li>
                                    <i className="ri-arrow-drop-left-line"></i> ﻧظﺎﻣﯾﺔ وﻣﺿﻣوﻧﺔ{" "}
                                </li>
                                <li>
                                    <i className="ri-arrow-drop-left-line"></i> ﺑدون ﻣﺑﻠﻎ ﺗﺄﻣﯾن
                                </li>
                                <li>
                                    <i className="ri-arrow-drop-left-line"></i> اﻟﺗوﺻﯾل اﻟﻣﻧزﻟﻲ
                                    اﻟﺳرﯾﻊ ﺑدون رﺳوم
                                </li>
                            </ul>
                            <h3 className="title-internal-page">اﻟﻔروع</h3>
                            <h4 className="sub-title-internal-page">اﻟرﯾﺎض</h4>
                            <p className="description">
                                <strong>ﻣوﻗﻊ:</strong> ﺣﻲ اﻟﻣوﻧﺳﯾﺔ - ﺷﺎرع اﻟﺑﯾﺿﺎء{" "}
                                <a href="#"> ﻋرض اﻟﺧرﯾطﺔ </a>
                                <br/>
                                <strong>اﻟرﻗم اﻟﻣوﺣد:</strong> 920000239
                                <br/>
                                <strong>اﻟﺑرﯾد اﻻﻟﻛﺗروﻧﻲ:</strong>{" "}
                                <a href="mailto:info@jr.sa"> info@jr.sa </a>
                                <br/>
                                <strong>ﻣواﻋﯾد اﻟﻌﻣل:</strong> اﻷﺣد إﻟﻰ اﻟﺧﻣﯾس ﻣن اﻟﺳﺎﻋﺔ 8:00
                                ﺻﺑﺎﺣًﺎ إﻟﻰ اﻟﺳﺎﻋﺔ 5:00 ﻣﺳﺎء
                            </p>
                            <h4 className="sub-title-internal-page">دﻣﺎم </h4>
                            <p className="description">
                                <strong>ﻣوﻗﻊ:</strong> ﺣﻲ اﻟﺷﺎطﺊ – أﺑراج اﻟﺷﺎطﺊ A – اﻟطﺎﺑﻖ
                                اﻟراﺑﻊ – ﻣﻛﺗب 42 <a href="#"> ﻋرض اﻟﺧرﯾطﺔ </a>
                                <br/>
                                <strong>اﻟرﻗم اﻟﻣوﺣد:</strong> 920000239
                                <br/>
                                <strong>هاتف الفرع: </strong> 0136680018
                                <br/>
                                <strong>اﻟﺑرﯾد اﻻﻟﻛﺗروﻧﻲ:</strong>{" "}
                                <a href="mailto:info@jr.sa"> info@jr.sa </a>
                                <br/>
                                <strong>ﻣواﻋﯾد اﻟﻌﻣل:</strong> اﻷﺣد إﻟﻰ اﻟﺧﻣﯾس ﻣن اﻟﺳﺎﻋﺔ 8:00
                                ﺻﺑﺎﺣًﺎ إﻟﻰ اﻟﺳﺎﻋﺔ 5:00 ﻣﺳﺎء
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mt-4  d-flex justify-content-center mb-4"></div>
            </div>
        </main>
    );
}

export default About;
