import React, { useState, useEffect } from "react";
import { Modal, Alert, Spinner } from "react-bootstrap";
import { authenticationService } from "./authentification.service";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { baseUrl } from "./config";
import Axios from "axios";

function getSearchParams(k) {
  var p = {};
  window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (s, k, v) {
    p[k] = v;
  });
  return k ? p[k] : p;
}
function setQueryStringWithoutPageReload(qsValue) {
  const newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    qsValue;

  window.history.pushState({ path: newurl }, "", newurl);
}

function Contracts(props) {
  const [packs, setPacks] = useState([]);
  const [vpayment_type, setvpayment_type] = useState(false);
  const [payment_type, setPayment_type] = useState("");
  const [duration, setDuration] = useState("");
  const [vduration, setvDuration] = useState(false);
  const [base64_img, setbase64_img] = useState({ file: "", name: "ملف ..." });
  const [setting, setSetting] = useState({});

  const [employee, setEmployee] = useState({});
  const [contracts, setContracts] = useState([]);
  const [erroralert, seterroralert] = useState("");
  const [successalert, setsuccessalert] = useState("");
  const [modalrequest, setmodalrequest] = useState(false);
  const [modal_extend, setModal_extend] = useState({
    status: false,
    contract: [],
  });
  const [modal_detail, setModal_detail] = useState({
    status: false,
    employee: [],
  });
  const [contract_id, setcontract_id] = useState(0);

  const [new_id, setnew_id] = useState(0);


  const [type, settype] = useState("");
  const [vtype, setvtype] = useState(false);
  const [loading, setloading] = useState(false);
  const [employees, setemployees] = useState([]);
  const [price, setprice] = useState(0);
  const [pack, setPack] = useState({});
  const [alerte, setalert] = useState({ status: false, message: "" });


  const [description, setdescription] = useState("");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let error = getSearchParams("error");
    let success = getSearchParams("success");
    if (error) {
      seterroralert(error);
      setQueryStringWithoutPageReload("");
    }
    if (success) {
      setsuccessalert(success);
      setQueryStringWithoutPageReload("");
    }
    Axios.get(`${baseUrl}/api/packs`).then((v) => {
      console.log(v.data.data);
      setPacks(v.data.data);
    });
    Axios.get(`${baseUrl}/api/settings/1`).then((v) => {
      console.log(v.data.data);
      setSetting(v.data.data);
    });

    authenticationService.get_contracts().then((data) => {
      console.log(data);
      setContracts(data);
    });
  }, []);


  const extend_contract = () => {
    
    if (
 (payment_type === "")
   || (payment_type === "bank_transfert" && base64_img.file === "")
   || (!setting.online_payment && payment_type === "payment")
   || (!setting.banktransfert_payment && payment_type === "bank_transfert")
   || duration === ""
 ) {
  if (duration === "")setvDuration(true)
  if (payment_type === "")setvpayment_type(true)
  setalert({ status: true, message: t("wrongfield") })


 }
 else{
  setloading(true);
  let payement_type = payment_type;
  let image_payement = base64_img.file;
  let contract_id=modal_extend.contract.id;
  let pack_duration = duration.split("_")[1];

  authenticationService
          .extend(
            price,
            contract_id,
            payement_type,
            pack_duration,
            image_payement
          )
          .then((v) => {
            console.log(v);
            if (v.url) {
              document.location = v.url;
            } else {

             
              setPayment_type("");
              setDuration("");
              setloading(false);
              console.log(v.id);
              setQueryStringWithoutPageReload(`?id=${v.id}&success=${t("requestsendextendsucess")}`);
              props.callbackwindow("contract");
            }
          })
          .catch((e) => {
           
            setloading(false);
            setPayment_type("");
            setDuration("");
            //setsuccessorder(true)
            console.log(e);
            if (e.code === 307) {
              setalert({ status: true, message: e.error });
            } else {
              setalert({ status: true, message: "مشكلة إتصال" });
            }
          });

 }

  };

  const changeworkerlist = () => {
    let res = employees.map((employ) => {
      return (
        <div className="col-md-12">
          <div
            className={
              employee.id === employ.id ? "card-worker active" : "card-worker"
            }
          >
            <div className="top-card-worker">
              <div className="photo-worker">
                <img
                  src={
                    employ.image
                      ? `${baseUrl}/${employ.image}`
                      : "./img/no-image.png"
                  }
                  alt=""
                />
              </div>
              <div className="info-worker">
                <span className="name-worker">{employ.name}</span>
                <span className="country">
                  {" "}
                  {i18n.t(modal_detail.employee.nationality_value)}
                </span>
                <span className="sex-age">
                  <b>
                    {i18n.t("age")}: {moment().diff(employ.birth_date, "years")}{" "}
                    {i18n.t("year")}
                  </b>{" "}
                </span>
                <span className="sex-age">
                  {i18n.t("sexe")}:{" "}
                  {employ.sexe === "Female" ? i18n.t("female") : i18n.t("male")}{" "}
                </span>
                <span className="sex-age">
                  {i18n.t("religion")}:{" "}
                  {employ.religion === "muslim"
                    ? i18n.t("muslim")
                    : i18n.t("not_muslim")}
                </span>
                <span className="sex-age">
                  <b>
                    {i18n.t("experience")}: {employ.experience} {i18n.t("year")}
                  </b>
                </span>
              </div>
            </div>
            <div className="bottom-worker">
              <a
                href="#"
                onClick={() => {
                  setModal_detail({ status: true, employee: employ });
                }}
                className="more-info"
              >
                {i18n.t("cv")}
              </a>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setEmployee(employ);
                  let text = `Request to change worker associated with contract number ${new_id} with worker(new):\nName: ${employ.name}\nID Number: ${employ.id_number}\nholder number: ${employ.id}`;

                  //console.log(text2)
                  // setdescription(text2)
                }}
                className="btn reserv-worker"
              >
                {i18n.t("choose")}
              </a>
            </div>
          </div>
        </div>
      );
    });
    return res;
  };

  const submit_request = (e) => {
    e.preventDefault();
    setloading(true);
    let validation = true;
    let newdescription = description;
    if (type === "") {
      setvtype(true);
      validation = false;
    }
    if (type === "change_worker" && employee.name === undefined) {
      validation = false;
    }
    if (type === "change_worker") {
      newdescription = `طلب تغيير العامل المرتبط بالعقد رقم ${new_id}.\nالسبب: ${description} \n*العامل جديد:\nالاسم: ${employee.name} \nرقم التعريف: ${employee.id_number} \nرقم التسلسل: ${employee.id}`;
    }

    if (validation) {
      authenticationService
        .submit_request(contract_id, type, newdescription)
        .then((v) => {
          console.log(v);
          setloading(false);
          setmodalrequest(false);
          setdescription("");
          settype("");
          //   props.callbackcin(cin)
          //   props.callbackwindow('activation')
        })
        .catch((e) => {
          console.log(e);
          setloading(false);
        });
    } else {
      setloading(false);
    }
  };
  const durationList = () => {
    let res = [];
    let list = [
      "pack_1",
      "pack_2",
      "pack_3",
      "pack_4",
      "pack_5",
      "pack_6",
      "pack_12",
    ];
    console.log("modal_extend", modal_extend);
    

    if (pack) {
      for (let i = 0; i < list.length; i++) {
        let pa = list[i];
        if (pack[pa] && pack[pa] > 0) {
          res.push(<option value={pa}>{pa.split("_")[1]}</option>);
        }
      }
    }
    return res;
  };

  const contracts_list = () => {
    let res = [];
    for (let i = 0; i < contracts.length; i++) {
      
      let id = contracts[i].id;
      let employ = contracts[i].employee;
      
      let contract = (contracts[i].contract_id)?contracts.find((v)=>{return v.id===contracts[i].contract_id}):contracts[i];
     
      res.push(
        <tr>
           <td className="contact-name-worker" data-label="#">
            
              {contracts[i].new_id}
            
          </td>
          <td className="contact-name-worker" data-label={i18n.t("worker")}>
            <a
              href="#"
              onClick={() => {
                setModal_detail({ status: true, employee: employ });
              }}
              className="more-info"
            >
              {contracts[i].employee.name}
            </a>
          </td>
          <td className="contact-name-services" data-label={t("nationality")}>
            {" "}
            {i18n.t(contracts[i].employee.nationality_value)}
          </td>
          <td className="contact-name-services" data-label={t("age")}>
            {" "}
            {moment().diff(contracts[i].employee.birth_date, "years")}
          </td>
          <td className="contact-name-worker" data-label={t("sexe")}>
            {contracts[i].employee.sexe === "Female"
              ? i18n.t("female")
              : i18n.t("male")}
          </td>
          <td className="contact-name-services" data-label={t("servicetype")}>
            {contracts[i].employee.category}
          </td>

          <td className="contact-period" data-label={t("duration")}>
            {contracts[i].pack_duration}
          </td>
          <td className="contact-period" data-label={t("start_at")}>
            {moment(contracts[i].start_at).format("YYYY-M-D")}
          </td>
          <td className="contact-period" data-label={t("end_at")}>
            {moment(contracts[i].end_at).format("YYYY-M-D")}
          </td>
          <td className="contact-total-price" data-label={t("total_v")}>
            {contracts[i].price}
            {t("sr")}
          </td>
          <td className="contact-total-price" data-label={t("status")}>
            {i18n.t(contracts[i].status)}
          </td>
          <td className="contact-total-price row">
            <button
              style={{ padding: 4, margin: 0, backgroundColor: "#fff" }}
              className="btn btn-primary col-4"
              onClick={() => {
                setQueryStringWithoutPageReload(`?id=${id}`);
                props.callbackwindow("contract");
              }}
            >
              {" "}
              <i style={{ color: "#eaa22f" }} className="ri-eye-fill"></i>
            </button>
        {  (moment().diff(contracts[i].end_at)<0 && contracts[i].employee.deleted_at===null)?<>  <button
              onClick={async () => {
                setcontract_id(id);
                setnew_id(contract.new_id)
                let employees1 = await Axios.get(
                  `${baseUrl}/api/replacelist/${id}`
                );
                setemployees(employees1.data.data);
                setmodalrequest(true);
              }}
              style={{ padding: 4, margin: 0, backgroundColor: "#fff" }}
              className="btn btn-primary col-4"
            >
              <i
                style={{ color: "#eaa22f" }}
                className="ri-settings-3-fill"
              ></i>
            </button>

            {/*<button*/}
            {/*  onClick={async () => {*/}
            {/*    let mypack = packs.find((v) => {*/}
            {/*      return v.id === contract.pack_id;*/}
            {/*    });*/}
            {/*    setPack(mypack)*/}


            {/*    setModal_extend({ status: true, contract: contract });*/}
            {/*  }}*/}
            {/*  style={{ padding: 4, margin: 0, backgroundColor: "#fff" }}*/}
            {/*  className="btn btn-primary col-4"*/}
            {/*>*/}
            {/*  <i style={{ color: "#eaa22f" }} className="ri-add-line"></i>*/}
            {/*</button>*/}
            
            </>:null}
          </td>
        </tr>
      );
    }
    return res;
  };
  const _uploadFile = (e) => {
    //...
    const _inp = e.target;
    const _files = _inp.files;

    Array.from(_files).forEach((file) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log(reader.result);
        setbase64_img({ file: reader.result, name: file.name });
      };
    });
    _inp.value = "";
  };


  return (
    <main role="services">
      <div className="container">
        <div className="row d-flex justify-content-center mt-4 mb-5">
          <div className="col-md-12 text-center">
            <h2 className="title-page">{t("contracts")}</h2>
          </div>
        </div>
        <div className="row mt-4  d-flex justify-content-center mb-4 mt-5">
          <div className="col-md-12">
            <div className="default-content">
              {props.successorder ? (
                <Alert variant="success">{t("requestsendsucess")}</Alert>
              ) : null}
              {successalert.length > 0 ? (
                <Alert variant="success">{t("orderpaysucess")}</Alert>
              ) : null}
              {erroralert.length > 0 ? (
                erroralert === "CANCELED" ? (
                  <Alert variant="danger">{t("paymentcanceled")}</Alert>
                ) : (
                  <Alert variant="danger">{t("failedpayment")}</Alert>
                )
              ) : null}
              <Modal
                show={modalrequest}
                onHide={() => {
                  setmodalrequest(false);
                }}
                dialogClassName="modal-default"
                aria-labelledby="exampleModalCenterTitle"
              >
                <div className="modal-dialog " style={{ margin: 0 }}>
                  <div className="modal-content">
                    <Modal.Header className="modal-header">
                      <div className="content-info-header-modal">
                        <button
                          type="button"
                          className="close"
                          onClick={() => {
                            setmodalrequest(false);
                            setdescription("");
                            settype("");
                          }}
                        >
                          <span aria-hidden="true">
                            <i className="ri-close-line"></i>
                          </span>
                        </button>
                        <h5
                          className="modal-title"
                          id="exampleModalCenterTitle"
                        >
                          {t("newrequest")}
                        </h5>
                      </div>

                      <div className="content-save-info">
                        <button
                          type="button"
                          disabled={loading}
                          className="btn btn-primary save-location"
                          onClick={submit_request}
                        >
                          {loading ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : null}
                          {t("submit")}
                        </button>
                      </div>
                    </Modal.Header>
                    <Modal.Body className="custom-form-default modal-body">
                      <div
                        classeName="col-12"
                        style={{ color: "#000", margin: 15 }}
                      >
                        <label style={{ color: "#000" }}>
                          {t("contractid")} :
                        </label>
                        {new_id}{" "}
                      </div>
                      <div
                        className={
                          vtype
                            ? "form-group not-valid-form col-md-12 mt-2 mb-4"
                            : "form-group col-md-12 mt-2 mb-4"
                        }
                      >
                        <select
                          onChange={(e) => {
                            settype(e.target.value);
                            setdescription("");
                            // setMessagealert('')
                          }}
                          className="form-control"
                          id="inputf4"
                        >
                          <option value="">{t("requesttype")}</option>
                          {/* <option value="contract_extend">
                            {t("contract_extend")}{" "}
                          </option> */}
                          <option value="change_worker">
                            {t("change_worker")}{" "}
                          </option>
                          <option value="agency_transfert">
                            {t("agency_transfert")}
                          </option>
                          <option value="contract_cancel">
                            {t("contract_cancel")}{" "}
                          </option>
                          <option value="other">{t("other")} </option>
                        </select>
                        <span className="focus-border"></span>
                      </div>
                      <div className="form-group col-md-12 mt-2 mb-4">
                        <textarea
                          style={{ height: 150 }}
                          type="text"
                          onChange={(e) => {
                            setdescription(e.target.value);
                            // setNamev(false)
                            // setMessagealert('')
                          }}
                          className="form-control"
                          id="input18"
                          placeholder={t("description")}
                        />

                        {type === "change_worker" ? (
                          <>
                            <h4> {i18n.t("listemployment")} </h4>
                            <div className="row">{changeworkerlist()}</div>
                          </>
                        ) : null}

                        <span className="focus-border"></span>
                      </div>
                    </Modal.Body>
                  </div>
                </div>
              </Modal>

              <Modal
                show={modal_extend.status}
                onHide={() => {
                  setModal_extend({
                    status: false,
                    contract: [],
                  });
                }}
                dialogClassName="modal-default"
                aria-labelledby="exampleModalCenterTitle"
              >
                <div className="modal-dialog " style={{ margin: 0 }}>
                  <div className="modal-content">
                    <Modal.Header className="modal-header">
                      <div className="content-info-header-modal">
                        <button
                          type="button"
                          className="close"
                          onClick={() => {
                            setModal_extend({
                              status: false,
                              contract: [],
                            });
                          }}
                        >
                          <span aria-hidden="true">
                            <i className="ri-close-line"></i>
                          </span>
                        </button>
                        <h5
                          className="modal-title"
                          id="exampleModalCenterTitle"
                        >
                          {t("contract_extend")}
                        </h5>
                      </div>

                      <div className="content-save-info">
                        <button
                          type="button"
                          disabled={loading}
                          className="btn btn-primary save-location"
                          onClick={extend_contract}
                        >
                          {loading ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : null}
                          {t("submit")}
                        </button>
                      </div>
                    </Modal.Header>
                    <Modal.Body
                      style={{
                        direction: i18n.language === "ar" ? "rtl" : "ltr",
                      }}
                      className="custom-form-default modal-body"
                    >
                      {alerte.status ? (
              <Alert variant="danger">{alerte.message}</Alert>
            ) : null}
                      <div
                        classeName="col-12"
                        style={{ color: "#000", margin: 15 }}
                      >
                        <label style={{ color: "#000" }}>
                          {t("contractid")} :
                        </label>
                        {modal_extend.contract.new_id}{" "}
                      </div>
                      <div
                        className={
                          vduration
                            ? "form-group not-valid-form col-md-12 mt-2 mb-4"
                            : "form-group col-md-12 mt-2 mb-4"
                        }
                      >
                        <h5 style={{ margin: 5 }}>
                          {i18n.t("contract_period")}
                        </h5>

                        <select
                          onChange={(e) => {
                            setDuration(e.target.value);
                            setvDuration(false)
                            if(e.target.value!=="")
                            {
                              let tax = (pack[e.target.value] * pack["tax"] ) / 100;
                              let myprice = pack[e.target.value] + tax;
                              setprice(myprice)
                            
                            }
                            else
                            setprice(0)
                          }}
                          className="form-control"
                          id="inputf4"
                        >
                          <option value="">
                            {" "}
                            {i18n.t("contract_period_months")}{" "}
                          </option>
                          {durationList()}
                        </select>
                        <span className="focus-border"></span>
                      </div>
                      <div
                        classeName="col-12"
                        style={{ color: "#000", margin: 15 }}
                      >
                        <label style={{ color: "#000" }}>
                          {t("serviceprice")} :
                        </label>
                        {price} {i18n.t("sr")}
                      </div>
                      <div className="form-group col-md-12 mt-2 mb-4">
                        <h5 style={{ margin: 5 }}>{i18n.t("pay_complete")}</h5>
                        <div
                          onChange={(e) => {
                            console.log(e.target.id);
                            setPayment_type(e.target.id);
                            setvpayment_type(false);
                          }}
                          className={
                            vpayment_type
                              ? "row custom-form-default not-valid-form mt-4"
                              : "row custom-form-default mt-4"
                          }
                        >
                          <div className="col-md-12">
                            <div className="custom-control custom-radio mb-4">
                              <input
                                checked={payment_type === "bank_transfert"}
                                type="radio"
                                id="bank_transfert"
                                name="payment_type"
                                className="custom-control-input"
                                disabled={!setting.banktransfert_payment}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="bank_transfert"
                              >
                                {i18n.t("banktransfer")}
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="custom-control custom-radio mb-4">
                              <input
                                checked={payment_type === "payment"}
                                type="radio"
                                id="payment"
                                name="payment_type"
                                className="custom-control-input"
                                disabled={!setting.online_payment}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="payment"
                              >
                                {i18n.t("onlinepayment")}
                              </label>
                            </div>
                          </div>
                        </div>

                        {payment_type === "bank_transfert" ? (
                          <>
                            <div className="row">
                              {" "}
                              <div className="col-6">
                                {" "}
                                <ul>
                                  <li>
                                    حساب شركة عبد المجيد عبد الرحمن الجريسي
                                    للاستقدام
                                  </li>
                                  <li>{i18n.t("rajhibank")} </li>
                                  <li>
                                    {i18n.t("accountnumber")}: 504608010080654
                                  </li>
                                  <li>
                                    {i18n.t("iban")}: SA5080000504608010080654
                                  </li>
                                </ul>
                              </div>
                              <div className="col-6">
                                <img width="70%" src="./img/rajhi.png" alt="" />
                              </div>
                            </div>

                            <label className="mb-5">
                              <a
                                style={{
                                  backgroundColor: "#eaa22f",
                                  color: "#fff",
                                }}
                                className="btn btn-primary btn-pay"
                              >
                                {" "}
                                <i
                                  style={{ fontSize: 25 }}
                                  className="ri-file-upload-line"
                                ></i>{" "}
                                {i18n.t("uploadfile")} : {base64_img.name}
                              </a>

                              <input
                                type="file"
                                style={{ display: "none" }}
                                size="10"
                                multiple={false}
                                onChange={_uploadFile}
                                accept="image/*,application/pdf"
                              />
                            </label>
                          </>
                        ) : null}

                        <br />
                      </div>
                    </Modal.Body>
                  </div>
                </div>
              </Modal>

              <Modal
                show={modal_detail.status}
                onHide={() => setModal_detail({ status: false, employee: [] })}
                dialogClassName="modal-default"
                aria-labelledby="exampleModalCenterTitle"
              >
                <div className="modal-dialog " style={{ margin: 0 }}>
                  <div className="modal-content">
                    <Modal.Header className="modal-header">
                      <div className="content-info-header-modal">
                        <button
                          type="button"
                          className="close"
                          onClick={() =>
                            setModal_detail({
                              status: false,
                              employee: [],
                            })
                          }
                        >
                          <span aria-hidden="true">
                            <i className="ri-close-line"></i>
                          </span>
                        </button>
                        <h5
                          className="modal-title"
                          id="exampleModalCenterTitle"
                        >
                          {i18n.t("cvdata")}
                        </h5>
                      </div>
                    </Modal.Header>
                    <Modal.Body className="custom-form-default modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="resum-worker">
                            <div className="top-resum-worker">
                              <div className="info-resum-worker">
                                <div className="photo-worker">
                                  <img
                                    src={
                                      modal_detail.employee.image
                                        ? `${baseUrl}/${modal_detail.employee.image}`
                                        : "./img/no-image.png"
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="descr-resum-worker">
                                  <span className="name-worker">
                                    {modal_detail.employee.name}
                                  </span>
                                  <span className="number-worker">
                                    {modal_detail.employee.id_number}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="content-worker">
                              <div className="detailed-worker">
                                <span>{i18n.t("profession")}:</span>
                                {modal_detail.employee.category}{" "}
                              </div>
                              <div className="detailed-worker">
                                <span>{i18n.t("nationality")}:</span>
                                {i18n.t(
                                  modal_detail.employee.nationality_value
                                )}
                              </div>
                              <div className="detailed-worker">
                                <span>{i18n.t("age")}:</span>
                                {moment().diff(
                                  modal_detail.employee.birth_date,
                                  "years"
                                )}{" "}
                                {i18n.t("year")}{" "}
                              </div>
                              <div className="detailed-worker">
                                <span>{i18n.t("sexe")}:</span>
                                {modal_detail.employee.sexe === "Female"
                                  ? i18n.t("female")
                                  : i18n.t("male")}
                              </div>
                              <div className="detailed-worker">
                                <span>{i18n.t("religion")}:</span>{" "}
                                {modal_detail.employee.religion === "muslim"
                                  ? i18n.t("muslim")
                                  : i18n.t("not_muslim")}{" "}
                              </div>
                              <div className="detailed-worker">
                                <span>{i18n.t("length")}:</span>
                                {modal_detail.employee.length}
                                {i18n.t("m")}
                              </div>
                              <div className="detailed-worker">
                                <span>{i18n.t("weight")}:</span>
                                {modal_detail.employee.weight} {i18n.t("kg")}{" "}
                              </div>
                              <div className="detailed-worker">
                                <span>{i18n.t("skills")}:</span>
                                {modal_detail.employee.skills}
                              </div>
                              <div className="detailed-worker">
                                <span>{i18n.t("experience")}:</span>
                                {modal_detail.employee.experience}
                              </div>
                              <div className="detailed-worker">
                                <span>{i18n.t("last_work_adress")}:</span>
                                {modal_detail.employee.last_work_adress}{" "}
                              </div>
                              {/* <div className="detailed-worker"><span>إجادة اللغة العربية:</span> {(modal_detail.employee.arab_speak === "yes") ? "نعم" : " لا"}</div>
                                      <div className="detailed-worker"><span>إجادة اللغة الانجليزية:</span> {(modal_detail.employee.english_speak === "yes") ? "نعم" : " لا"}</div>
                                    */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </div>
                </div>
              </Modal>

              <div className="custom-table contact-table-custom">
                <table className="table contract-table">
                  <thead>
                    <tr>
                    <th scope="col">#</th>
                      <th scope="col">{t("worker")}</th>
                      <th scope="col">{t("nationality")}</th>
                      <th scope="col">{t("age")}</th>
                      <th scope="col">{t("sexe")}</th>
                      <th scope="col">{t("servicetype")}</th>
                      <th scope="col">{t("duration")}</th>
                      <th scope="col"> {t("start_at")}</th>
                      <th scope="col"> {t("end_at")}</th>
                      <th scope="col">{t("total_v")}</th>
                      <th scope="col">{t("status")}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>{contracts_list()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
export default Contracts;
