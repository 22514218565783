import React, {useEffect, useState} from "react";
import {Alert, Form} from "react-bootstrap";
import {authenticationService} from './authentification.service';
import {useTranslation} from "react-i18next";

function setQueryStringWithoutPageReload(qsValue) {
    const newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        qsValue;

    window.history.pushState({path: newurl}, "", newurl);
}

function getSearchParams(k) {
    var p = {};
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (s, k, v) {
        p[k] = v;
    });
    return k ? p[k] : p;
}

function Login(props) {
    const [cin, setCin] = useState('');
    const [password, setPassword] = useState('');
    const [cinv, setCinv] = useState(false);
    const [passwordv, setPasswordv] = useState(false);
    const [messagealert, setMessagealert] = useState("");
    const {t, i18n} = useTranslation();
    const [isreset, setisreset] = useState(false);


    useEffect(() => {
        let resetpassword = getSearchParams("reset");

        if (resetpassword) {
            setisreset(true)
            setQueryStringWithoutPageReload("")
        }

    }, [])
    const submitLogin = (e) => {
        e.preventDefault();
        let validation = true
        if (cin.length !== 10) {
            setCinv(true)
            validation = false
        }
        if (password.length < 6) {
            setPasswordv(true)
            validation = false
        }


        if (validation) {
            authenticationService.login(cin, password)
                .then((v) => {
                    console.log(v)
                    props.callbackwindow('main')

                })
                .catch((e) => {

                    console.log(e)
                    if (e.code === 403) {
                        setMessagealert(t("inactiveaccount"))
                        props.callbackcin(cin)
                        setQueryStringWithoutPageReload("?inactiveaccount=true")
                        props.callbackwindow('activation')
                    } else {
                        setMessagealert(t("invalidpassword"))
                    }

                })
        } else {
            setMessagealert(t("wrongfield"))
        }
        return false

    }

    return (<main role="login">

            <div className="container-fluid banner d-flex justify-content-center align-items-center">
                <div className="container">
                    <h2>{t("findservice")}</h2>
                </div>
            </div>
            <div className="container">
                <div className="row d-flex justify-content-center mt-4">
                    <div className="col-lg-6 col-md-10 text-center">
                        <h3> {t("login")}</h3>
                        <div className="login custom-form-default mt-4">

                            <Form className="row" onSubmit={submitLogin}>
                                {(messagealert.length > 0) ?
                                    <Alert style={{width: "100%"}} variant="danger">{messagealert}</Alert> : null}
                                {(isreset) ? <Alert style={{width: "100%"}}
                                                    variant="success">{t("passwordresetsuccess")}</Alert> : null}

                                <div
                                    className={(cinv) ? "form-group not-valid-form col-md-12 mt-2 mb-4" : "form-group col-md-12 mt-2 mb-4"}>
                                    <input type="text" onChange={(e) => {
                                        setCin(e.target.value)
                                        setCinv(false)
                                        setMessagealert('')
                                    }} className="form-control" id="input" placeholder={t("enteridcard")}/>
                                    <span className="focus-border"></span>
                                </div>
                                <div
                                    className={(passwordv) ? "form-group not-valid-form col-md-12 mt-2 mb-4" : "form-group col-md-12 mt-2 mb-4"}>
                                    <input type="password" onChange={(e) => {
                                        setPassword(e.target.value)
                                        setPasswordv('')
                                        setMessagealert('')
                                    }} className="form-control" id="input" placeholder={t("enterpassword")}/>
                                    <span className="focus-border"></span>
                                </div>
                                <div className="col-md-12 mt-2 mb-4">
                                    <button type="submit"
                                            className="btn btn-primary btn-login w-100  justify-content-center">{t("enter")}</button>
                                </div>
                            </Form>
                            <div className="col-md-12 mt-2 mb-2">
                                <a onClick={() => {
                                    props.callbackwindow('forgotpassword')
                                }} href="#">
                                    {t("forgotpassword")}


                                </a>
                            </div>
                            <div className="col-md-12 mt-2 mb-4">
                                <p>
                                    {t("newuser")}؟<a onClick={() => {
                                    props.callbackwindow('register')
                                }} href="#">{t("subscribenow")}</a>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
    )
}

export default Login;
