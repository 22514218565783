import React, {useEffect, useState} from "react";
import {Alert} from "react-bootstrap";
import {authenticationService} from "./authentification.service";
import moment from "moment";
import {useTranslation} from "react-i18next";

function getSearchParams(k) {
  var p = {};
  window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (s, k, v) {
    p[k] = v;
  });
  return k ? p[k] : p;
}
function setQueryStringWithoutPageReload(qsValue) {
  const newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    qsValue;

  window.history.pushState({ path: newurl }, "", newurl);
}

function Contract(props) {
  const [contracts, setContracts] = useState({});
  const [erroralert, seterroralert] = useState("");
  const [successalert, setsuccessalert] = useState("");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let error = getSearchParams("error");
    let success = getSearchParams("success");
    let id = getSearchParams("id");
    if (error) {
      seterroralert(error);
      setQueryStringWithoutPageReload("");
    }
    if (success) {
      setsuccessalert(success);
      setQueryStringWithoutPageReload("");
    }
    if (id) {
      setQueryStringWithoutPageReload("");
    }

    authenticationService.get_contract(id).then((data) => {
      console.log(data);
      setContracts(data);
    });
  }, []);

  const contracts_list2 = () => {
    let res = [];
    let mycontract=contracts.contracts||[]
    for (let i = 0; i < mycontract.length; i++) {
      let id = mycontract[i].id;
      let employ = mycontract[i].employee;
      let contract = mycontract[i];
      res.push(
        <tr>
          <td className="contact-name-services" data-label={t("nationality")}>
            {" "}
            {i18n.t(mycontract[i].new_id)}
          </td>
       

          <td className="contact-period" data-label={t("duration")}>
            {mycontract[i].pack_duration}
          </td>
          <td className="contact-period" data-label={t("start_at")}>
            {moment(mycontract[i].start_at).format("YYYY-M-D")}
          </td>
          <td className="contact-period" data-label={t("end_at")}>
            {moment(mycontract[i].end_at).format("YYYY-M-D")}
          </td>
          <td className="contact-total-price" data-label={t("total_v")}>
            {mycontract[i].price}
            {t("sr")}
          </td>
          <td className="contact-total-price" data-label={t("status")}>
            {i18n.t(mycontract[i].status)}
          </td>
          <td className="contact-total-price">
            {/* <button
              style={{ padding: 5, margin: 0, backgroundColor: "#fff" }}
              className="btn btn-primary"
              onClick={() => {
                setQueryStringWithoutPageReload(`?id=${id}`);
                props.callbackwindow("contract");
              }}
            >
              {" "}
              <i style={{ color: "#eaa22f" }} className="ri-eye-fill"></i>
            </button> */}
          </td>
        </tr>
      );
    }
    return res;
  };

  const contracts_list = () => {
    let res = [];
    if (contracts.employee) {
      res.push(
        <div>
          <div classeName="col-md-12">
            <label style={{ color: "#000" }}>{t("id_number")} :</label>
            {contracts.employee.id_number}{" "}
          </div>
          <div classeName="col-md-12">
            <label style={{ color: "#000" }}>{t("worker")} :</label>
            {contracts.employee.name}{" "}
          </div>
          <div classeName="col-12">
            <label style={{ color: "#000" }}>{t("nationality")} :</label>{" "}
            {i18n.t(contracts.employee.nationality_value)}{" "}
          </div>
          <div classeName="col-12">
            <label style={{ color: "#000" }}>{t("age")} :</label>{" "}
            {moment().diff(contracts.employee.birth_date, "years")}{" "}
          </div>
          <div classeName="col-12">
            <label style={{ color: "#000" }}>{t("sexe")} :</label>
            {contracts.employee.sexe === "Female"
              ? i18n.t("female")
              : i18n.t("male")}{" "}
          </div>
          <div classeName="col-12">
            <label style={{ color: "#000" }}>{t("servicetype")} :</label>
            {contracts.employee.category}{" "}
          </div>

          <div classeName="col-12">
            <label style={{ color: "#000" }}>{t("duration")} :</label>
            {contracts.pack_duration}{" "}
          </div>
          <div classeName="col-12">
            <label style={{ color: "#000" }}>{t("start_at")} :</label>
            {moment(contracts.start_at).format("YYYY-M-D")}{" "}
          </div>
          <div classeName="col-12">
            <label style={{ color: "#000" }}>{t("end_at")} :</label>
            {moment(contracts.end_at).format("YYYY-M-D")}{" "}
          </div>
          <div classeName="col-12">
            <label style={{ color: "#000" }}>{t("total_v")} :</label>
            {contracts.price}
            {t("sr")}{" "}
          </div>
          <div classeName="col-12">
            <label style={{ color: "#000" }}>{t("status")} :</label>{" "}
            {i18n.t(contracts.status)}{" "}
          </div>
        </div>
      );
    }
    return res;
  };
  const requests_list = () => {
    let res = [];
    let requests = contracts.requestchanges || [];
    for (let i = 0; i < requests.length; i++) {
      res.push(
        <tr>
          <td
            className="contact-name-worker"
            data-label={t("requesttype") + " :"}
          >
            {i18n.t(contracts.requestchanges[i].type)}
          </td>
          <td
            className="contact-name-services"
            data-label={t("description") + " :"}
          >
            {" "}
            {contracts.requestchanges[i].description}
          </td>
          <td className="contact-total-price" data-label={t("status") + " :"}>
            {i18n.t(contracts.requestchanges[i].status)}
          </td>
        </tr>
      );
    }
    return res;
  };

  return (
    <main role="services">
      <div className="container">
        <div className="container-fluid top-nav-step">
          <div className="container content-top-nav-step d-flex align-items-center">
            <a
              href="#"
              onClick={() => {
                props.callbackwindow("contracts");
              }}
            >
              <i
                className={
                  i18n.language === "ar"
                    ? "ri-arrow-right-line"
                    : "ri-arrow-left-line"
                }
              ></i>
            </a>
          </div>
        </div>
        <div className="row  justify-content-center mt-5 mb-5">
          <div className="col-md-12 text-center">
            <h2 className="title-page">
              {" "}
              <span style={{float:"right"}} >  {t("contractid")}</span > <span style={{float:"right",marginLeft:20}} >{contracts.new_id}</span>
            </h2>
          </div>
        </div>
        <div
          style={{ fontSize: 30 }}
          className="row mt-4  d-flex justify-content-center mb-4 mt-5"
        >
          <div className="col-md-12">
            <div className="default-content">
              {props.successorder ? (
                <Alert variant="success">{t("requestsendsucess")}</Alert>
              ) : null}
              {successalert.length > 0 ? (
                <Alert variant="success">{t("orderpaysucess")}</Alert>
              ) : null}
              {erroralert.length > 0 ? (
                erroralert === "CANCELED" ? (
                  <Alert variant="danger">{t("paymentcanceled")}</Alert>
                ) : (
                  <Alert variant="danger">{t("failedpayment")}</Alert>
                )
              ) : null}
              <div className="container">
                <h4> {t("contractdetails")}:</h4>

                {contracts_list()}

                <h4> {t("requestschange")}:</h4>
                <div className="custom-table contact-table-custom">
                  <table className="table contract-table">
                    <thead>
                      <tr>
                        <th scope="col">{t("requesttype")}</th>
                        <th scope="col">{t("description")}</th>
                        <th scope="col">{t("status")}</th>
                      </tr>
                    </thead>
                    <tbody>{requests_list()}</tbody>
                  </table>
                </div>
                <h4> {t("relatedcontracts")}:</h4>
                <div className="custom-table contact-table-custom">
                <table className="table contract-table">
                  <thead>
                    <tr>
                     
                      <th scope="col">{t("contractid")}</th>
                      <th scope="col">{t("duration")}</th>
                      <th scope="col"> {t("start_at")}</th>
                      <th scope="col"> {t("end_at")}</th>
                      <th scope="col">{t("total_v")}</th>
                      <th scope="col">{t("status")}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>{contracts_list2()}</tbody>
                </table>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
export default Contract;
