import React, {useState} from "react";
import {Alert, Form} from "react-bootstrap";
import {authenticationService} from './authentification.service';
import {useTranslation} from "react-i18next";


function setQueryStringWithoutPageReload(qsValue) {
    const newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        qsValue;

    window.history.pushState({path: newurl}, "", newurl);
}

function Register(props) {
    const [cin, setCin] = useState('');
    const [cinv, setCinv] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordv, setPasswordv] = useState(false);
    const [confirmpassword, setConfirmpassword] = useState('');
    const [confirmpasswordv, setConfirmpasswordv] = useState(false);
    const [name, setName] = useState("");
    const [namev, setNamev] = useState(false);
    const [lastname, setLastname] = useState("");
    const [lastnamev, setLastnamev] = useState(false);
    const [nationality, setNationality] = useState("saudian");
    const [social_state, setSocial_state] = useState("married");
    const [sexe, setSexe] = useState("male");
    const [phone, setPhone] = useState("");
    const [phonev, setPhonev] = useState(false);
    const [phone2, setPhone2] = useState("");
    const [email, setEmail] = useState("");
    const [messagealert, setMessagealert] = useState("");
    const {t, i18n} = useTranslation();


    const submitRegister = (e) => {
        e.preventDefault();
        let validation = true
        let confirm = true
        if (cin.length !== 10) {
            setCinv(true)
            validation = false
        }
        if (phone.length !== 12) {
            setPhonev(true)
            validation = false
        }
        if (name === '') {
            setNamev(true)
            validation = false
        }
        if (lastname === '') {
            setLastnamev(true)
            validation = false
        }
        if (password.length < 6) {
            setPasswordv(true)
            validation = false
        }
        if (confirmpassword !== password) {
            setPasswordv(true)
            setConfirmpasswordv(true)
            confirm = false

        }


        if (validation && confirm) {
            authenticationService.register(cin, password, name, lastname, nationality, social_state, sexe, phone, phone2, email)
                .then((v) => {
                    console.log(v)
                    props.callbackcin(cin)
                    setQueryStringWithoutPageReload("?inactiveaccount=true")
                    props.callbackwindow('activation')

                })
                .catch((e) => {
                    console.log(e)
                    if (e.code === 302) {
                        setMessagealert(t("customerexist"))
                    } else {
                        setMessagealert(t("connectionproblem"))
                    }

                })
        } else {
            if (!validation) {
                setMessagealert(t("wrongfield"))
            } else {
                setMessagealert(t("passwordnotmatch"))
            }


        }
        return false
    }
    return (
        <main role="login">

            <div className="container-fluid banner d-flex justify-content-center align-items-center">
                <div className="container">
                    <h2>{t("findservice")}</h2>
                </div>
            </div>
            <div className="container">
                <div className="row d-flex justify-content-center mt-4">
                    <div className="col-lg-6 col-md-10 text-center">
                        <h3> {t("register")}</h3>
                        <div className="login custom-form-default mt-4">

                            <Form className="row " onSubmit={submitRegister}>
                                {(messagealert.length > 0) ?
                                    <Alert style={{width: "100%"}} variant="danger">{messagealert}</Alert> : null}
                                <div
                                    className={(namev) ? "form-group not-valid-form col-md-12 mt-2 mb-4" : "form-group col-md-12 mt-2 mb-4"}>
                                    {(namev) ? <li style={{
                                        color: "#dc3545",
                                        textAlign: "right",
                                        fontSize: 13
                                    }}> {t("requiredfield")}</li> : null}
                                    <input type="text" onChange={(e) => {
                                        setName(e.target.value)
                                        setNamev(false)
                                        setMessagealert('')
                                    }} className="form-control" id="input18" placeholder="الاسم الأول"/>
                                    <span className="focus-border"></span>


                                </div>

                                <div
                                    className={(lastnamev) ? "form-group not-valid-form col-md-12 mt-2 mb-4" : "form-group col-md-12 mt-2 mb-4"}>
                                    {(lastnamev) ? <li style={{
                                        color: "#dc3545",
                                        textAlign: "right",
                                        fontSize: 13
                                    }}> {t("requiredfield")}</li> : null}

                                    <input type="text" onChange={(e) => {
                                        setLastname(e.target.value)
                                        setLastnamev(false)
                                        setMessagealert('')
                                    }} className="form-control" id="input47" placeholder={t("family")}/>
                                    <span className="focus-border"></span>
                                </div>
                                <div className="form-group col-md-12 mt-2 mb-4">

                                    <select onChange={(e) => {
                                        setNationality(e.target.value)
                                        setMessagealert('')
                                    }} className="form-control" id="inputf4" placeholder={t("nationality")}>
                                        <option value="saudian">{t("saudian")} </option>
                                        <option value="not_saudian">{t("not_saudian")} </option>
                                    </select>
                                    <span className="focus-border"></span>
                                </div>


                                <div
                                    className={(cinv) ? "form-group not-valid-form col-md-12 mt-2 mb-4" : "form-group col-md-12 mt-2 mb-4"}>
                                    {(cinv) ? <li style={{
                                        color: "#dc3545",
                                        textAlign: "right",
                                        fontSize: 13
                                    }}> {t("cin10")}</li> : null}

                                    <input type="text" onChange={(e) => {
                                        setCin(e.target.value)
                                        setCinv(false)
                                        setMessagealert('')
                                    }} name="cin" className="form-control" id="input12" placeholder={t("enteridcard")}/>
                                    <span className="focus-border"></span>
                                </div>
                                <div className="form-group col-md-12 mt-2 mb-4">

                                    <select onChange={(e) => {
                                        setSocial_state(e.target.value)
                                        setMessagealert('')
                                    }} className="form-control" id="input33" placeholder="الحالية الاجتماعية">
                                        <option value="married">{t("married")} </option>
                                        <option value="single">{t("not_married")} </option>
                                    </select>
                                    <span className="focus-border"></span>
                                </div>


                                <div className="form-group col-md-12 mt-2 mb-4">

                                    <select onChange={(e) => {
                                        setSexe(e.target.value)
                                        setMessagealert('')
                                    }} className="form-control" id="input34" placeholder="الجنس">
                                        <option value="male">{t("male")} </option>
                                        <option value="female">{t("female")} </option>
                                    </select>
                                    <span className="focus-border"></span>
                                </div>

                                <div
                                    className={(phonev) ? "form-group not-valid-form col-md-12 mt-2 mb-4" : "form-group col-md-12 mt-2 mb-4"}>
                                    {(phonev) ? <li style={{
                                        color: "#dc3545",
                                        textAlign: "right",
                                        fontSize: 13
                                    }}> {t("phone966")}</li> : null}
                                    <input type="number" onChange={(e) => {
                                        setPhone(e.target.value)
                                        setPhonev(false)
                                        setMessagealert('')
                                    }} className="form-control" id="input96"
                                           placeholder={`9665XXXXXXXXX ${t("phone")} `}/>
                                    <span className="focus-border"></span>
                                </div>
                                <div className="form-group col-md-12 mt-2 mb-4">
                                    <input type="number" onChange={(e) => {
                                        setPhone2(e.target.value)
                                        setMessagealert('')
                                    }} className="form-control" id="input88" placeholder={t("phone2")}/>
                                    <span className="focus-border"></span>
                                </div>


                                <div
                                    className={(passwordv) ? "form-group not-valid-form col-md-12 mt-2 mb-4" : "form-group col-md-12 mt-2 mb-4"}>
                                    {(passwordv) ? <li style={{
                                        color: "#dc3545",
                                        textAlign: "right",
                                        fontSize: 13
                                    }}> {t("password8")}</li> : null}
                                    <input type="password" onChange={(e) => {
                                        setPassword(e.target.value)
                                        setPasswordv(false)
                                        setMessagealert('')
                                    }} className="form-control" id="input16" placeholder={t("enterpassword")}/>
                                    <span className="focus-border"></span>
                                </div>
                                <div
                                    className={(confirmpasswordv) ? "form-group not-valid-form col-md-12 mt-2 mb-4" : "form-group col-md-12 mt-2 mb-4"}>
                                    {(confirmpasswordv) ? <li style={{
                                        color: "#dc3545",
                                        textAlign: "right",
                                        fontSize: 13
                                    }}> {t("passwordnotmatch")}</li> : null}
                                    <input type="password" onChange={(e) => {
                                        setConfirmpassword(e.target.value)
                                        setConfirmpasswordv(false)
                                        setMessagealert('')
                                    }} className="form-control" id="input17" placeholder={t("confirmpassword")}/>
                                    <span className="focus-border"></span>
                                </div>
                                <div className="form-group col-md-12 mt-2 mb-4">
                                    <input type="text" onChange={(e) => {
                                        setEmail(e.target.value)
                                        setMessagealert('')
                                    }} className="form-control" id="input65" placeholder={t("email")}/>
                                    <span className="focus-border"></span>
                                </div>
                                <div className="col-md-12 mt-2 mb-4">
                                    <button type="submit"
                                            className="btn btn-primary btn-login w-100  justify-content-center">{t("subscribe")}</button>
                                </div>
                            </Form>
                            {/* <div className="col-md-12 mt-2 mb-2">
                <a href="#">نسيت كلمة المرور؟</a>
            </div> */}
                            <div className="col-md-12 mt-2 mb-4">
                                <p>
                                    {/* مستخدم جديد؟<a href="#">قم بالتسجيل</a> */}
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>

    )
}

export default Register;
