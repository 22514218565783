import React, {useEffect, useState} from "react";
import {Alert, Form} from "react-bootstrap";
import {authenticationService} from './authentification.service';
import {useTranslation} from "react-i18next";

function getSearchParams(k) {
    var p = {};
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (s, k, v) {
        p[k] = v;
    });
    return k ? p[k] : p;
}

function setQueryStringWithoutPageReload(qsValue) {
    const newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        qsValue;

    window.history.pushState({path: newurl}, "", newurl);
}

function Activation(props) {
    const [code, setCode] = useState('');
    const [codev, setCodev] = useState(false);
    const [phone, setphone] = useState('');
    const [phonev, setPhonev] = useState(false);
    const [messagealert, setMessagealert] = useState("");
    const [messageresend, setmessageresend] = useState("");
    const {t, i18n} = useTranslation();
    const [resendstate, setresendstate] = useState(true)
    const [isfogotpassword, setIsfogotpassword] = useState(false)
    const [ischangephone, setischangephone] = useState(false)
    useEffect(() => {
        let forgotpassword = getSearchParams("forgotpassword");
        let inactiveaccount = getSearchParams("inactiveaccount");

        if (forgotpassword) {
            setQueryStringWithoutPageReload("")
            setmessageresend(t("codesendedsuccess"));
            setIsfogotpassword(true)
        }
        if (inactiveaccount) {
            setQueryStringWithoutPageReload("")
            setMessagealert(t("inactiveaccount"))
        }
    }, [])

    const submitActivation = (e) => {
        e.preventDefault();

        if (ischangephone) {
            let validation = true
            if (phone.length !== 12) {
                setPhonev(true)
                validation = false
            }
            if (validation) {
                authenticationService.changephone(props.cin, phone)
                    .then((v) => {
                        setischangephone(false)
                        setmessageresend(t("phonechangeandsmssent"))
                    })
                    .catch((e) => {

                        setMessagealert(t("connectionproblem"))

                    })
            } else {
                // setMessagealert(t("wrongfield"))
            }

        } else {
            let validation = true
            if (code.length !== 6) {
                setCodev(true)
                validation = false
            }
            if (validation) {
                console.log("props.cin, code", props.cin, code)
                authenticationService.active(props.cin, code)
                    .then((v) => {
                        console.log(v)
                        if (isfogotpassword) {
                            setQueryStringWithoutPageReload(`?resetpassword=${code}`);
                            props.callbackpass(code)
                            props.callbackwindow('resetpassword')
                        } else {
                            props.callbackwindow('login')
                        }

                    })
                    .catch((e) => {

                        console.log(e)
                        setMessagealert(t("wrongactivationnumber"))

                    })
            } else {
                setMessagealert(t("wrongfield"))
            }
        }

        return false
    }

    return (<main role="login">

            <div className="container-fluid banner d-flex justify-content-center align-items-center">
                <div className="container">
                    <h2>{t("findservice")}</h2>
                </div>
            </div>
            <div className="container">
                <div className="row d-flex justify-content-center mt-4">
                    <div className="col-lg-6 col-md-10 text-center">
                        <h3> {(!ischangephone) ? t("activateaccount") : t("changephone")}</h3>
                        <div className="login custom-form-default mt-4">

                            <Form className="row" onSubmit={submitActivation}>
                                {(messagealert.length > 0) ?
                                    <Alert style={{width: "100%"}} variant="danger">{messagealert}</Alert> : null}
                                {(messageresend.length > 0) ?
                                    <Alert style={{width: "100%"}} variant="success">{messageresend}</Alert> : null}


                                {(!ischangephone) ? <div
                                        className={(codev) ? "form-group not-valid-form col-md-12 mt-2 mb-4" : "form-group col-md-12 mt-2 mb-4"}>
                                        <input type="text" onChange={(e) => {
                                            setCode(e.target.value)
                                            setCodev(false)
                                            setMessagealert('')
                                            setmessageresend('')
                                        }} className="form-control" id="input" placeholder={t("enteractivation")}/>
                                        <span className="focus-border"></span>
                                    </div> :
                                    <div
                                        className={(phonev) ? "form-group not-valid-form col-md-12 mt-2 mb-4" : "form-group col-md-12 mt-2 mb-4"}>
                                        {(phonev) ? <li style={{
                                            color: "#dc3545",
                                            textAlign: "right",
                                            fontSize: 13
                                        }}> {t("phone966")}</li> : null}
                                        <input type="text" onChange={(e) => {
                                            setphone(e.target.value)
                                            setPhonev(false)
                                            setMessagealert('')
                                            setmessageresend('')
                                        }} className="form-control" id="input" placeholder={t("phone")}/>
                                        <span className="focus-border"></span>
                                    </div>
                                }

                                <div className="col-md-12 mt-2 mb-4">
                                    <button type="submit"
                                            className="btn btn-primary btn-login w-100  justify-content-center">{(ischangephone) ? t("save") : t("activate")}</button>
                                </div>
                            </Form>
                            <div className="col-md-12 mt-2 mb-4">
                                <p>
                                    <a onClick={() => {
                                        if (resendstate) {
                                            authenticationService.resend(props.cin)
                                            setmessageresend(t("codesendedsuccess"));
                                            setresendstate(false)
                                        }

                                    }} href="#">{t("resendconfirmation")}</a>

                                </p>
                                <p>
                                    <a onClick={(e) => {
                                        e.preventDefault()
                                        setischangephone(true)
                                        setMessagealert("")
                                    }
                                    } href="#">{t("changephone")}</a>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
    )
}

export default Activation;
